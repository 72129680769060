import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Card as CardType } from "@/types/Card";
import { cn } from "@/lib/utils";
import cardBack from "/card-back.jpg";
import { PushableButton } from "@/components/PushableButton";
import { useWindowSize } from "@/hooks/useWindowSize";
import { ArrowDown } from "lucide-react";
import { Textures } from "./Textures";

const useHoverState = () => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [randomLift] = useState(() => 50 + Math.random() * 40); // Random lift between 30-45px

  return {
    hoveredIndex,
    setHoveredIndex,
    randomLift
  };
};

const DailyDrawHero: React.FC = () => {
  const [cards, setCards] = useState<CardType[]>([]);
  const [draggedCards, setDraggedCards] = useState<number[]>([]);
  const [isDoneDragging, setIsDoneDragging] = useState(false);
  const [isRevealed, setIsRevealed] = useState(false);
  const hoverState = useHoverState();
  const [isInitialAnimationComplete, setIsInitialAnimationComplete] =
    useState(false);

  const theStarTarotCard = {
    id: 0,
    name: "The Fool",
    arcanaType: "Major Arcana",
    cardNumber: 0,
    suit: null,
    imageUrl:
      "https://tarot-kjkapeerbjmrgiku.nyc3.digitaloceanspaces.com/default/00-fool.jpg",
    visualDescription:
      "A young man in a red and yellow striped shirt and blue and yellow striped pants, holding a small dog, walking towards a cliff. He is carefree and innocent, symbolizing new beginnings and the journey of life.",
    keywords: ["new beginnings", "innocence", "carefree", "journey"]
  };

  // Initialize cards only once when component mounts
  useEffect(() => {
    const initialCards = Array.from({ length: 78 }, (_, index) => ({
      ...theStarTarotCard,
      id: index,
      originalCardNumber: index
    }));

    const shuffledCards = shuffleArray(initialCards);
    setCards(shuffledCards);
  }, []); // Empty dependency array means this runs once on mount

  const shuffleArray = (array: CardType[]): CardType[] => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  const handleDragEnd = async (cardId: number) => {
    setDraggedCards((prevDraggedCards) => {
      if (!prevDraggedCards.includes(cardId)) {
        return [...prevDraggedCards, cardId];
      }
      return prevDraggedCards;
    });
    setIsDoneDragging(true);
  };

  const handleCardReveal = async () => {
    console.log("Revealing card :)");
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5,
        delay: 0.5,
        ease: "easeInOut",
        staggerChildren: 1
      }
    }
  };

  return (
    <div className="relative z-20 m-2 pt-16" id="daily-draw-hero">
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -100 }}
          transition={{ duration: 0.5, delay: 1.3 }}
          className="relative aspect-[16/20] md:aspect-[16/9] max-w-7xl mx-auto rounded-2xl z-50 bg-primary border border-primary-foreground/10 p-1 md:p-4"
        >
          <div className="z-50 border-primary-foreground/10 border overflow-hidden relative rounded-2xl flex h-full flex-1 flex-col bg-background md:peer-data-[variant=inset]:m-2 md:peer-data-[state=collapsed]:peer-data-[variant=inset]:ml-2 md:peer-data-[variant=inset]:ml-0 md:peer-data-[variant=inset]:rounded-xl md:peer-data-[variant=inset]:shadow">
            <Textures type="paper-1" />
            <div className="h-full">
              <div className="relative h-full overflow-hidden">
                <div className="absolute z-30 bottom-0 transform w-full h-full flex justify-center items-center pointer-events-none">
                  {/* Centered Card Outline */}
                  <div className="absolute inset-0 flex items-center justify-center space-x-10">
                    <AnimatePresence>
                      <motion.div
                        initial={{ opacity: 0, y: 25 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 25 }}
                        transition={{ duration: 0.5, delay: 3 }}
                        className={cn(
                          "-mt-24 w-[150px]  aspect-[375/629] flex border relative border-dashed border-primary-foreground/20 bg-background-dark rounded-lg items-center justify-center"
                        )}
                      />
                    </AnimatePresence>
                  </div>
                  <AnimatePresence>
                    {isDoneDragging && isRevealed && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { delay: 0.7 } }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                        className="absolute inset-0 flex items-start justify-center mt-4"
                      >
                        <span className="uppercase font-sans absolute text-primary-foreground/70 text-center text-sm flex flex-col items-center gap-y-2">
                          your reading is ready
                        </span>
                      </motion.div>
                    )}
                  </AnimatePresence>
                  <AnimatePresence>
                    {isDoneDragging && !isRevealed && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { delay: 0.7 } }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                        className="absolute inset-0 flex items-start justify-center mt-4"
                      >
                        <span className="uppercase font-sans absolute text-primary-foreground/70 text-center text-sm flex flex-col items-center gap-y-2">
                          Click the card
                          {/* <ArrowDown className="w-4 h-4" /> */}
                        </span>
                      </motion.div>
                    )}
                  </AnimatePresence>
                  <AnimatePresence>
                    {!isDoneDragging && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { delay: 2 } }}
                        exit={{ opacity: 0, transition: { delay: 0 } }}
                        transition={{ duration: 0.5 }}
                        className="absolute inset-0 flex items-start justify-center mt-4"
                      >
                        <span className="uppercase font-sans absolute text-primary-foreground/70 text-center text-sm flex flex-col items-center gap-y-2">
                          Drag a Card <br />
                          <ArrowDown className="animate-bounce w-4 h-4" />
                        </span>
                      </motion.div>
                    )}
                  </AnimatePresence>
                  {isRevealed && (
                    <AnimatePresence>
                      {/* Fade In */}
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5, delay: 1.5 }}
                        className="absolute inset-0 z-50 flex justify-center flex-start"
                      >
                        <div className="absolute left-0 w-full flex items-center justify-center mt-16">
                          <PushableButton
                            className="pointer-events-auto z-50"
                            text="Go To Reading"
                            size="small"
                            onClick={() => {
                              document
                                .querySelector("#reading-demo")
                                ?.scrollIntoView({
                                  behavior: "smooth",
                                  block: "start",
                                  inline: "nearest"
                                });
                            }}
                          />
                        </div>
                      </motion.div>
                    </AnimatePresence>
                  )}
                  {cards.length > 0 && (
                    <AnimatePresence>
                      <motion.div
                        className="pointer-events-none absolute inset-0 flex items-center justify-center"
                        variants={containerVariants}
                      >
                        {cards.map((card, index) => (
                          <Card
                            key={card.id}
                            card={card}
                            index={index}
                            total={cards.length}
                            draggedCards={draggedCards}
                            onDragEnd={handleDragEnd}
                            isDoneDragging={isDoneDragging}
                            isRevealed={isRevealed}
                            setIsRevealed={setIsRevealed}
                            onReveal={handleCardReveal}
                            hoverState={hoverState}
                            isInitialAnimationComplete={
                              isInitialAnimationComplete
                            }
                            setIsInitialAnimationComplete={
                              setIsInitialAnimationComplete
                            }
                          />
                        ))}
                      </motion.div>
                    </AnimatePresence>
                  )}
                </div>
              </div>
            </div>
          </div>
          <CornerImages />
        </motion.div>
      </AnimatePresence>
      <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
        <AnimatePresence>
          <motion.img
            initial={{ opacity: 0, y: 130 }}
            animate={{
              opacity: 1,
              rotate: 360,
              y: 0
            }}
            transition={{
              opacity: { duration: 0.5, delay: 2 },
              rotate: {
                duration: 200,
                repeat: Infinity,
                ease: "linear"
              },
              y: {
                duration: 1,
                delay: 4
              }
            }}
            src="/card-assets/sun.png"
            className="w-[400px] relative -top-[260px] opacity-50 origin-center lg:block hidden"
          />
        </AnimatePresence>
      </div>
    </div>
  );
};

interface CardProps {
  card: CardType;
  index: number;
  total: number;
  onDragEnd: (cardId: number) => void;
  isDoneDragging: boolean;
  draggedCards: number[];
  isRevealed: boolean;
  setIsRevealed: (isRevealed: boolean) => void;
  onReveal: (cardId: number) => void;
  hoverState: {
    hoveredIndex: number | null;
    setHoveredIndex: (index: number | null) => void;
    randomLift: number;
  };
  isInitialAnimationComplete: boolean;
  setIsInitialAnimationComplete: (isInitialAnimationComplete: boolean) => void;
}

const Card: React.FC<CardProps> = ({
  card,
  index,
  total,
  onDragEnd,
  isDoneDragging,
  draggedCards,
  isRevealed,
  setIsRevealed,
  onReveal,
  hoverState,
  isInitialAnimationComplete,
  setIsInitialAnimationComplete
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const isDragged = draggedCards.includes(card.id);
  const cardRef = useRef<HTMLDivElement>(null);

  const { width: windowWidth } = useWindowSize();
  const scale = getResponsiveScale(windowWidth);
  const draggedPosition = useDraggedPosition();

  // Calculate card position on the arc
  const { x, y, angle } = useCardPosition(index, total);

  // Define styles and animations
  const cardStyles = useCardStyles(isDoneDragging, isDragged);
  const cardAnimations = useCardAnimations(
    isDragged,
    isRevealed,
    x,
    y,
    angle,
    index,
    hoverState,
    isInitialAnimationComplete,
    setIsInitialAnimationComplete
  );

  // Add parallax effect
  useEffect(() => {
    if (isDragged && isRevealed) {
      const handleMouseMove = (e: MouseEvent) => {
        if (cardRef.current) {
          const rect = cardRef.current.getBoundingClientRect();
          const cardCenterX = rect.left + rect.width / 2;
          const cardCenterY = rect.top + rect.height / 2;

          const mouseX = (e.clientX - cardCenterX) / (rect.width / 2);
          const mouseY = (e.clientY - cardCenterY) / (rect.height / 2);

          const tiltX = -mouseY * 0.5;
          const tiltY = -mouseX * 1.5;

          cardRef.current.style.transform = `
            perspective(1000px)
            rotateX(${tiltX}deg)
            rotateY(${tiltY}deg)
            translateY(${draggedPosition.y})
            scale(${scale})
          `;
        }
      };

      window.addEventListener("mousemove", handleMouseMove);

      return () => {
        window.removeEventListener("mousemove", handleMouseMove);
      };
    }
  }, [isDragged, isRevealed, scale, draggedPosition]);

  const handleCardClick = async () => {
    if (isDragged && !isRevealed) {
      setIsRevealed(true);
      await onReveal(card.id);
    }
  };

  return (
    <motion.div
      ref={cardRef}
      className={cn(
        "z-50 absolute w-[180px] sm:w-[200px] overflow-hidden aspect-[375/629] top-3/4 rounded-lg lg:rounded-2xl p-3 pb-[46px] bg-[#f4e7d8] border border-black border-opacity-40 pointer-events-auto",
        {
          "cursor-grabbing": isDragging && !isRevealed,
          "cursor-grab": !isDragging && !isRevealed,
          "m-0 z-10": isDragged
        }
      )}
      style={{
        ...cardStyles,
        transformStyle: "preserve-3d",
        transition:
          isDragged && isRevealed ? "transform 0.1s ease-out" : undefined
      }}
      {...cardAnimations}
      drag={(!isDragged && draggedCards.length === 0) || !isDoneDragging}
      dragConstraints={{
        top: -window.innerHeight + 300,
        bottom: 0,
        left: -500,
        right: 500
      }}
      dragElastic={0.6}
      dragTransition={{
        bounceStiffness: 300,
        bounceDamping: 20,
        power: 0.2,
        timeConstant: 200,
        restDelta: 0.5,
        min: -800,
        max: 800
      }}
      whileDrag={{
        scale: 1.02,
        rotate: isDragging ? [-0.5, 0.5] : 0,
        transition: {
          rotate: {
            repeat: Infinity,
            repeatType: "mirror",
            duration: 0.3
          }
        }
      }}
      onDragStart={() => setIsDragging(true)}
      onDragEnd={(_, info) =>
        handleDragEnd(info, isDragged, card.id, onDragEnd, setIsDragging)
      }
      onClick={handleCardClick}
    >
      <div
        className="relative w-full h-full z-10"
        style={{ transformStyle: "preserve-3d" }}
      >
        <CardNumber
          isRevealed={isRevealed}
          cardNumber={card.cardNumber}
          isDragged={isDragged}
        />
        <CardBack isDragged={isDragged} isRevealed={isRevealed} />
        <CardFront card={card} isDragged={isDragged} isRevealed={isRevealed} />
      </div>
    </motion.div>
  );
};

const useCardPosition = (index: number, total: number) => {
  const angle = (index / (total - 1)) * 90 - 45; // -45 to 45 degrees
  const radius = 800;
  const scaleY = 0.5;
  const x = Math.sin((angle * Math.PI) / 180) * radius;
  const topYMargin = 50;
  const y =
    -Math.cos((angle * Math.PI) / 180) * radius * scaleY +
    radius * scaleY +
    topYMargin;
  return { x, y, angle };
};

const useDraggedPosition = () => {
  const { width, height } = useWindowSize();
  const scale = getResponsiveScale(width);

  // Calculate center position - adjust to be more centered in the target area
  const centerY = height * 0.3; // Position at 30% from the top of the viewport
  const targetOffset = 100; // Additional offset to fine-tune position
  const upwardOffset = 50; // Move card up by 50px

  return {
    x: 0,
    y: `calc(-${centerY}px + ${targetOffset}px - ${upwardOffset}px)`,
    scale: scale,
    rotate: 0
  };
};

const useCardStyles = (isDoneDragging: boolean, isDragged: boolean) => ({
  transformStyle: "preserve-3d" as const,
  transition: "transform 0.3s ease-out",
  ...(isDoneDragging && isDragged ? { margin: 0 } : {})
});

const useCardAnimations = (
  isDragged: boolean,
  isRevealed: boolean,
  x: number,
  y: number,
  angle: number,
  index: number,
  hoverState: CardProps["hoverState"],
  isInitialAnimationComplete: boolean,
  setIsInitialAnimationComplete: (isInitialAnimationComplete: boolean) => void
) => {
  const draggedPosition = useDraggedPosition();
  const { hoveredIndex, randomLift } = hoverState;

  // Add useEffect to detect when initial animation is complete
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsInitialAnimationComplete(true);
    }, 600 + index * 0.02 * 1000);

    return () => clearTimeout(timer);
  }, []);

  const getNeighborLift = (distance: number) => {
    const maxNeighborDistance = 2; // How many cards to the left and right to affect
    const baseNeighborLift = randomLift * 0.5; // Base lift amount for neighbors (50% of main lift)

    if (distance > maxNeighborDistance) return 0;

    return baseNeighborLift * (1 - distance / (maxNeighborDistance + 1));
  };

  const calculateLift = () => {
    if (hoveredIndex === null) return 0;

    if (hoveredIndex === index) {
      return randomLift;
    }

    const distance = Math.abs(hoveredIndex - index);
    return getNeighborLift(distance);
  };

  const calculateRotation = () => {
    return angle; // For now, just return the base angle
  };

  const getHoverTransition = () => ({
    duration: isInitialAnimationComplete ? 0.2 : 0.6,
    ease: "easeOut",
    delay: 0
  });

  return {
    initial: isDragged
      ? draggedPosition
      : {
          y: y + 200 + 10 * index,
          rotate: angle,
          rotateY: 180,
          opacity: 1
        },
    animate: isDragged
      ? {
          ...draggedPosition,
          rotateY: isRevealed ? 0 : 180,
          translateZ: isRevealed ? -100 : [2, 180, 2],
          rotateX: 5,
          rotate: 0,
          transition: {
            type: "spring",
            stiffness: 200,
            damping: 20
          }
        }
      : {
          x,
          y: y - calculateLift(),
          rotateY: 180,
          opacity: 1,
          transition: hoveredIndex !== null ? getHoverTransition() : undefined
        },
    whileHover:
      !isDragged && !isRevealed
        ? {
            x: x - 20,
            y: y - calculateLift(),
            rotate: calculateRotation(),
            transition: getHoverTransition()
          }
        : undefined,
    transition: {
      default: {
        type: "spring",
        stiffness: 300,
        damping: 25,
        duration: isInitialAnimationComplete ? 0.2 : 0.6,
        delay: isDragged ? 0 : isInitialAnimationComplete ? 0 : 1 + 0.02 * index
      },
      rotate: {
        duration: isInitialAnimationComplete ? 0.2 : 0.3,
        ease: "easeOut"
      }
    },
    onHoverStart: () => {
      if (!isDragged && !isRevealed) {
        hoverState.setHoveredIndex(index);
      }
    },
    onHoverEnd: () => {
      if (!isDragged && !isRevealed) {
        hoverState.setHoveredIndex(null);
      }
    }
  };
};

const handleDragEnd = (
  info: any,
  isDragged: boolean,
  cardId: number,
  onDragEnd: (cardId: number) => void,
  setIsDragging: (isDragging: boolean) => void
) => {
  // Calculate drag threshold as percentage of viewport height
  const dragThreshold = window.innerHeight * 0.6; // 60% of viewport height
  const dragDistance = Math.abs(info.offset.y);
  const dragVelocity = Math.abs(info.velocity.y);

  // Consider both distance and velocity for better touch interaction
  if (
    !isDragged &&
    (dragDistance > 100 || dragVelocity > 100 || info.point.y < dragThreshold)
  ) {
    onDragEnd(cardId);
    setIsDragging(false);
  } else if (!isDragged) {
    // If drag wasn't successful, animate back to original position
    setIsDragging(false);
  }
};

// Subcomponents for CardContent
const CardNumber: React.FC<{
  cardNumber: number;
  isDragged: boolean;
  isRevealed: boolean;
}> = ({ cardNumber, isDragged, isRevealed }) => (
  <motion.div
    animate={{
      opacity: isDragged && isRevealed ? 1 : 0,
      transition: { delay: 0.15 }
    }}
    className="flex items-center absolute w-full justify-center z-50 top-0"
  >
    <span className="absolute flex items-center justify-center font-rosarivo text-xs border border-t-0 border-black/60 bg-[#f4e7d8] z-50 font-left-0 mx-4 w-8 h-4 mt-4 rounded-br-full rounded-bl-full">
      <span className="z-10 text-xs -mt-2">{cardNumber}</span>
    </span>
  </motion.div>
);

const CardBack: React.FC<{ isDragged: boolean; isRevealed: boolean }> = ({
  isDragged,
  isRevealed
}) => (
  <motion.div
    className="absolute w-full h-[calc(100%+50px-1rem)] backface-hidden pointer-events-none border border-black border-opacity-70 rounded-md"
    style={{ backfaceVisibility: "hidden" }}
    animate={{ opacity: isDragged && isRevealed ? 0 : 1 }}
  >
    <Textures type="paper-1" />
    <img
      src={cardBack}
      className="object-cover w-full h-full pointer-events-none rounded-md relative z-10"
      alt="Card Back"
    />
  </motion.div>
);

const CardFront: React.FC<{
  card: CardType;
  isDragged: boolean;
  isRevealed: boolean;
}> = ({ card, isDragged, isRevealed }) => {
  // Get mouse position and percentage of the screen
  // Don't use any libraries for this, just plain JS
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const { width } = useWindowSize();
  const scale = getResponsiveScale(width);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      // Calculate the percentage of the screen
      const percentageX = e.clientX / window.innerWidth;
      const percentageY = e.clientY / window.innerHeight;

      setOffset({ x: percentageX, y: percentageY });
    };

    if (isDragged) window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [isDragged]);

  return (
    <motion.div
      className="absolute w-full h-full backface-hidden pointer-events-none"
      style={{ backfaceVisibility: "hidden" }}
      animate={{ opacity: isDragged && isRevealed ? 1 : 0 }}
      transition={{ duration: 0.1, delay: isDragged ? 0.2 : 0 }}
    >
      <div className="overflow-hidden relative w-full h-full rounded-t-md">
        <motion.img
          src="/rays.jpg"
          style={{
            transform: `translate(${(offset.x - 0.5) * 10}%, ${
              (offset.y - 0.5) * 10
            }%) scale(${scale})`
          }}
          className="absolute inset-0 mix-blend-color-dodge object-cover pointer-events-none opacity-30 rounded-md"
        />
        {/* <Textures type="paper-1" /> */}
        <img
          src={card.imageUrl || ""}
          className="object-cover rounded-md rounded-b-none w-full h-full pointer-events-none border border-black/40 aspect-[375/629]"
          alt={card.name}
        />
      </div>
      <div className="relative inset-x-0 bottom-0 bg-[#f4e7d8] border border-t-0 text-center border-black/40 rounded-b-md p-1">
        <span className="font-rosarivo text-xs">{card.name}</span>
      </div>
    </motion.div>
  );
};

const getResponsiveScale = (windowWidth: number) => {
  // if (windowWidth < 768) return 0.95; // md - reduced from 0.95
  if (windowWidth < 1024) return 0.95; // lg - reduced from 1.1
  return 1; // larger than 1800px - reduced from 1.3
};

const CornerImages = () => {
  return (
    <>
      <motion.img
        initial={{ opacity: 0, rotate: 10, y: 0, x: -50 }}
        animate={{
          opacity: 1,
          rotate: [0, 5, 0],
          y: [0, 50, 0],
          x: 0
        }}
        transition={{
          duration: 0.5,
          delay: 3.5,
          rotate: {
            duration: 10,
            repeat: Infinity,
            ease: "easeInOut"
          },
          y: {
            duration: 20,
            repeat: Infinity,
            ease: "easeInOut"
          }
        }}
        src="/card-assets/pents-01.png"
        className="w-32 absolute bottom-[5%] -right-24 lg:block hidden"
      />
      <motion.img
        initial={{ opacity: 0, rotate: 10, y: 0, x: -50 }}
        animate={{
          opacity: 3,
          rotate: [0, 5, 0],
          y: [0, 50, 0],
          x: 0
        }}
        transition={{
          duration: 0.5,
          delay: 2,
          rotate: {
            duration: 10,
            repeat: Infinity,
            ease: "easeInOut"
          },
          y: {
            duration: 25,
            repeat: Infinity,
            ease: "easeInOut"
          }
        }}
        src="/card-assets/sword-01.png"
        className="w-28 absolute -top-10 -right-20 lg:block hidden"
      />
      <motion.img
        initial={{ opacity: 0, rotate: -10, y: 0, x: 20 }}
        animate={{
          opacity: 1,
          rotate: [0, -5, 0],
          y: [0, 40, 0],
          x: 0
        }}
        transition={{
          duration: 0.5,
          delay: 2.5,
          rotate: {
            duration: 10,
            repeat: Infinity,
            ease: "easeInOut"
          },
          y: {
            duration: 20,
            repeat: Infinity,
            ease: "easeInOut"
          }
        }}
        src="/card-assets/wands-01.png"
        className="w-32 absolute -top-10 -left-24 lg:block hidden"
      />
      <motion.img
        initial={{ opacity: 0, rotate: -10, y: 0, x: 20 }}
        animate={{
          opacity: 1,
          rotate: [0, -5, 0],
          y: [0, 40, 0],
          x: 0
        }}
        transition={{
          duration: 0.5,
          delay: 3,
          rotate: {
            duration: 30,
            repeat: Infinity,
            ease: "easeInOut"
          },
          y: {
            duration: 26,
            repeat: Infinity,
            ease: "easeInOut"
          }
        }}
        src="/card-assets/cups-01.png"
        className="w-32 absolute bottom-2 -left-24 lg:block hidden"
      />
    </>
  );
};
export default DailyDrawHero;
