import { useState, useEffect } from "react";
import Layout from "@/components/layout";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Check } from "lucide-react";
import { PushableButton } from "@/components/PushableButton";
import { useToast } from "@/components/ui/use-toast";
import { motion } from "framer-motion";
import { useUserStore } from "@/stores/useUserStore";
import {
  PaymentElement,
  useStripe,
  useElements,
  Elements
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription
} from "@/components/ui/dialog";
import { cn } from "@/lib/utils";
import { Textures } from "@/components/Textures";

// Initialize Stripe
const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);

// Payment form component
const PaymentForm = ({
  onPaymentSuccess
}: {
  onPaymentSuccess: (paymentIntentId: string) => void;
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);

    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        redirect: "if_required",
        confirmParams: {
          payment_method_data: {
            billing_details: {} // This ensures 3D Secure works properly
          }
        }
      });

      if (error) {
        setErrorMessage(error.message);
        setIsProcessing(false);
      } else if (paymentIntent && paymentIntent.status === "succeeded") {
        onPaymentSuccess(paymentIntent.id);
      }
    } catch (e) {
      setErrorMessage("An unexpected error occurred.");
      setIsProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mt-4">
      <PaymentElement />
      {errorMessage && (
        <div className="mt-4 text-red-600 text-sm">{errorMessage}</div>
      )}
      <button
        type="submit"
        disabled={!stripe || isProcessing}
        className={cn(
          "mt-6 w-full bg-rose-600 text-white py-2 px-4 rounded-md",
          "hover:bg-rose-700 disabled:opacity-50 disabled:cursor-not-allowed",
          "transition-all duration-200 ease-in-out"
        )}
      >
        {isProcessing ? "Processing..." : "Complete Payment"}
      </button>
    </form>
  );
};

// Payment form wrapper
const PaymentFormWrapper = ({
  onPaymentSuccess,
  onClose
}: {
  onPaymentSuccess: (paymentIntentId: string) => void;
  onClose: () => void;
}) => {
  const [clientSecret, setClientSecret] = useState<string>();

  useEffect(() => {
    fetch(
      `${import.meta.env.VITE_API_URL}/api/payments/create-credits-intent`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      }
    )
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret))
      .catch((err) => {
        console.error("Error creating payment intent:", err);
        onClose(); // Close modal on error
      });
  }, []);

  if (!clientSecret) {
    return (
      <div className="flex items-center justify-center p-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-rose-600" />
      </div>
    );
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret,
        appearance: {
          theme: "stripe"
        }
      }}
    >
      <PaymentForm onPaymentSuccess={onPaymentSuccess} />
    </Elements>
  );
};

const Credits = () => {
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const { updateCredits, user } = useUserStore();

  // Mutation for adding credits after successful payment
  const addCreditsMutation = useMutation({
    mutationFn: async (paymentIntentId: string) => {
      const response = await fetch(
        `${import.meta.env.VITE_API_URL}/api/payments/add-credits`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`
          },
          body: JSON.stringify({ paymentIntentId })
        }
      );
      if (!response.ok) {
        throw new Error("Failed to add credits");
      }
      return response.json();
    },
    onSuccess: (data) => {
      updateCredits(data.credits);
      queryClient.invalidateQueries({ queryKey: ["currentUser"] });
      toast({
        title: "Success!",
        description: "222 credits have been added to your account."
      });
      setShowPaymentModal(false);
    },
    onError: () => {
      toast({
        title: "Error",
        description:
          "Failed to add credits to your account. Please contact support.",
        variant: "destructive"
      });
    }
  });

  const handlePaymentSuccess = (paymentIntentId: string) => {
    addCreditsMutation.mutate(paymentIntentId);
  };

  const includedFeatures = [
    "Daily Reading & Interpretation",
    "222 Credits to Explore Your Readings",
    "Personal AI Tarot Guide 24/7",
    "Deep Dive into Card Meanings",
    "Access Anywhere, Anytime"
  ];

  return (
    <Layout>
      <div className="flex items-center justify-center min-h-[calc(100vh-76px)]">
        <div className="max-w-2xl w-full mx-4">
          <motion.div
            style={{
              transformStyle: "preserve-3d"
            }}
            animate={{
              transform: "perspective(1000px) rotate3d(0, 0, 0, 6deg)",
              scale: 1
            }}
            transition={{
              duration: 1,
              ease: "easeOut"
            }}
            className={cn(
              "flex flex-col justify-between rounded-2xl p-8 relative border border-primary-foreground/20 shadow-2xl bg-primary"
            )}
          >
            <div className="absolute inset-x-4 inset-y-4 border-2 border-primary-foreground/20 rounded-xl"></div>
            <Textures type="paper-1" />
            <div className="relative z-10">
              <div className="flex items-center justify-center -mt-20 mb-6">
                <motion.div
                  animate={{
                    rotate: [0, 2, 0, -2, 0],
                    scale: [1, 1.05, 1],
                    y: [0, 10, 0]
                  }}
                  transition={{
                    rotate: {
                      repeat: Infinity,
                      repeatType: "loop",
                      duration: 15,
                      ease: "easeInOut"
                    },
                    scale: {
                      repeat: Infinity,
                      repeatType: "loop",
                      duration: 15,
                      ease: "easeInOut"
                    },
                    y: {
                      repeat: Infinity,
                      repeatType: "loop",
                      duration: 15,
                      ease: "easeInOut",
                      delay: 5
                    }
                  }}
                  className={cn(
                    "aspect-square bg-contain bg-center bg-no-repeat origin-center",
                    "w-48 h-48"
                  )}
                  style={{
                    backgroundImage: `url('/gemstones/amethyst-2.png')`
                  }}
                />
              </div>
              <h3
                className={cn(
                  "text-primary-foreground relative z-10",
                  "font-semibold mb-2 text-center",
                  "text-3xl leading-normal"
                )}
              >
                Crystal Clear <br /> Tarot Guidance
              </h3>

              <p className="mt-4 text-primary-foreground text-center max-w-[400px] mx-auto">
                Dive deeper into your tarot readings with personalized guidance.
                No subscriptions, no recurring fees.
              </p>

              <div className="flex items-center justify-center">
                <ul className="mt-8 space-y-3 text-primary-foreground">
                  {includedFeatures.map((feature) => (
                    <li key={feature} className="flex gap-3">
                      <Check className="h-6 w-5 flex-none text-rose-700" />
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="flex flex-col items-center justify-center gap-4 mt-5 mb-4 relative">
                {/* Rotating Background Blob */}
                <div className="absolute inset-0 flex items-center justify-center blur">
                  <motion.div
                    className={cn(
                      "-z-10 absolute",
                      "w-52 h-52",
                      "bg-yellow-600/50"
                    )}
                    initial={{ opacity: 0 }}
                    animate={{
                      opacity: [0.1, 0.2, 0.1],
                      scale: [0.7, 0.8, 0.7],
                      borderRadius: [
                        "30% 70% 70% 30% / 30% 30% 70% 70%",
                        "60% 40% 30% 70% / 60% 30% 70% 40%",
                        "30% 60% 70% 40% / 50% 60% 30% 60%",
                        "30% 70% 70% 30% / 30% 30% 70% 70%"
                      ]
                    }}
                    transition={{
                      duration: 16,
                      repeat: Infinity,
                      repeatType: "mirror",
                      ease: "easeInOut",
                      delay: 1.6,
                      opacity: {
                        repeat: 1,
                        duration: 8
                      }
                    }}
                  />
                </div>
                <div className="flex flex-col items-center justify-center text-primary-foreground text-4xl font-semibold relative drop-shadow-xl text-center space-y-4 mt-4">
                  <span>
                    <span className="text-5xl relative">
                      <span className="relative z-10 text-rose-600">222</span>
                      <span className="absolute inset-0 text-rose-600/75 top-2 blur-lg">
                        222
                      </span>
                    </span>
                    <span className="text-4xl"> Credits</span>
                  </span>
                  <span className="text-3xl font-semibold tracking-tight text-primary-foreground block">
                    For $19.99
                  </span>
                </div>
              </div>

              <div className="flex justify-center mt-8">
                <PushableButton
                  text="Get 222 Credits"
                  onClick={() => setShowPaymentModal(true)}
                  className="w-full max-w-xs"
                />
              </div>
            </div>
          </motion.div>
        </div>
      </div>

      {/* Payment Modal */}
      <Dialog
        open={showPaymentModal}
        onOpenChange={(open) => {
          if (!open) {
            setShowPaymentModal(false);
          }
        }}
      >
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            <DialogTitle className="text-2xl font-semibold text-center text-primary-foreground">
              Get More Credits
            </DialogTitle>
            <DialogDescription className="text-center text-primary-foreground">
              Add 222 message credits to deepen your Tarot journey.
            </DialogDescription>
          </DialogHeader>
          <PaymentFormWrapper
            onPaymentSuccess={handlePaymentSuccess}
            onClose={() => setShowPaymentModal(false)}
          />
        </DialogContent>
      </Dialog>
    </Layout>
  );
};

export default Credits;
