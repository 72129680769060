import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  getReading,
  sendUserMessage,
  sendChatMessage,
  toggleReadingFavorite
} from "@/utils/api";
import Layout from "@/components/layout";
import { motion } from "framer-motion";
import { Card as CardType, CardWithReversed } from "@/types/Card";
import { cn } from "@/lib/utils";
import { PushableButton } from "@/components/PushableButton";
import { Message } from "@/types/api";
import { Reading } from "@/types/api";
import { Badge } from "@/components/ui/badge";
import { Skeleton } from "@/components/ui/skeleton";
import { format } from "date-fns";
import { useUserStore } from "@/stores/useUserStore";
import { toast } from "@/components/ui/use-toast";
import { ToastAction } from "@/components/ui/toast";
import { Drawer, DrawerContent, DrawerTrigger } from "@/components/ui/drawer";
import { Button } from "@/components/ui/button";
import { Eye } from "lucide-react";
import { useBreadcrumbStore } from "@/stores/breadcrumb";
import { Textures } from "@/components/Textures";

const ReadingDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const readingId = parseInt(id!, 10);
  const [newMessage, setNewMessage] = useState("");
  const queryClient = useQueryClient();
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const user = useUserStore((state) => state.user);
  const updateCredits = useUserStore((state) => state.updateCredits);
  const { setDynamicSegment } = useBreadcrumbStore();

  const {
    data: reading,
    isLoading,
    error
  } = useQuery({
    queryKey: ["reading", readingId],
    queryFn: () => getReading(readingId),
    enabled: !!readingId && !isNaN(readingId)
  });

  const sendMessageMutation = useMutation({
    mutationFn: async (message: string) => {
      if (!reading) {
        throw new Error("Reading data is not available");
      }

      if (!user || user.messageCredits <= 0) {
        throw new Error("Insufficient message credits");
      }

      setNewMessage("");

      // Optimistically update the credits
      updateCredits(user.messageCredits - 1);

      // First, save the user message
      const newMessage = await sendUserMessage(
        message,
        reading.conversation.id
      );

      // Add the new message to the conversation history
      const updatedConversation = [
        ...reading.conversation.messages,
        newMessage
      ];

      return sendChatMessage(
        reading.conversation.id,
        reading.card.name,
        updatedConversation
      );
    },
    onMutate: async (message) => {
      await queryClient.cancelQueries({ queryKey: ["reading", readingId] });
      const previousReading = queryClient.getQueryData(["reading", readingId]);

      queryClient.setQueryData<Reading>(["reading", readingId], (old) => {
        if (!old) return old;
        return {
          ...old,
          conversation: {
            ...old.conversation,
            messages: [
              ...old.conversation.messages,
              {
                id: Date.now(),
                conversationId: old.conversation.id,
                role: "user",
                content: message,
                tokens: 0,
                createdAt: new Date().toISOString()
              }
            ]
          }
        };
      });

      return { previousReading };
    },
    onSuccess: (response) => {
      queryClient.setQueryData<Reading>(["reading", readingId], (old) => {
        if (!old) return old;
        return {
          ...old,
          conversation: {
            ...old.conversation,
            messages: [
              ...old.conversation.messages,
              {
                id: Date.now(),
                conversationId: old.conversation.id,
                role: "assistant",
                content: response.response,
                tokens: 0,
                createdAt: new Date().toISOString()
              }
            ]
          }
        };
      });
    },
    onError: (_error, _variables, context) => {
      // Use type-safe error handling
      queryClient.setQueryData(
        ["reading", readingId],
        context?.previousReading
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["reading", readingId] });
    }
  });

  const toggleFavoriteMutation = useMutation({
    mutationFn: toggleReadingFavorite,
    onSuccess: () => {
      // Invalidate the reading query to get fresh data
      queryClient.invalidateQueries({ queryKey: ["reading", readingId] });
    },
    onError: () => {
      toast({
        title: "Error",
        description: "Failed to update favorite status",
        variant: "destructive"
      });
    }
  });

  const handleSendMessage = async () => {
    if (!user || user.messageCredits <= 0) {
      toast({
        title: "Insufficient Credits",
        description: `You don't have enough credits. Buy credits to continue. ${
          user && !user.emailVerified
            ? `Want to try it out first? Verify your email to receive 5 free credits.`
            : ""
        }`,
        variant: "destructive",
        action:
          user && user.emailVerified ? (
            <ToastAction altText="Buy Credit">Buy Credits</ToastAction>
          ) : undefined
      });
      return;
    }

    if (newMessage.trim() && reading) {
      try {
        await sendMessageMutation.mutateAsync(newMessage);
      } catch (error) {
        console.error("Error sending message:", error);
        // Error toast is handled in the mutation's onError
      }
    }
  };

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [reading?.conversation.messages]);

  // Add this function to handle auto-resize
  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  // Adjust height when message changes
  useEffect(() => {
    adjustTextareaHeight();
  }, [newMessage]);

  useEffect(() => {
    if (reading) {
      document.title = `Reading - ${reading.card.name}`;
    }

    return () => {
      document.title = "Your Unique Tarot";
    };
  }, [id, reading]);

  useEffect(() => {
    if (reading) {
      setDynamicSegment(`/readings/${id}`, reading.card.name);
    }

    return () => {
      if (id) {
        setDynamicSegment(`/readings/${id}`, "");
      }
    };
  }, [id, reading, setDynamicSegment]);

  if (error)
    return (
      <Layout>
        <div>An error occurred: {(error as Error).message}</div>
      </Layout>
    );

  const guideFirstName = reading?.guide.name.split(" ")[0];

  // Create the ToggleFavorite component
  const ToggleFavorite = ({ readingId }: { readingId?: number }) => {
    const [isFavorited, setIsFavorited] = useState(false);

    useEffect(() => {
      if (reading) {
        setIsFavorited(reading.isFavorite);
      }
    }, [reading]);

    const handleFavoriteClick = () => {
      if (!readingId) return;
      setIsFavorited((prev) => !prev);
      toggleFavoriteMutation.mutate(readingId);
    };

    return (
      <Button
        variant="outline"
        size="icon"
        onClick={handleFavoriteClick}
        className={cn(
          "transition-colors duration-200 border-primary-darker text-primary-foreground",
          isFavorited ? "bg-primary/10" : ""
        )}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill={isFavorited ? "currentColor" : "none"}
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="h-4 w-4"
        >
          <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
        </svg>
      </Button>
    );
  };

  if (!reading?.card) return null;

  return (
    <Layout disablePadding>
      <div className="grid grid-cols-5 gap-8 max-w-7xl px-4 md:mx-auto h-full">
        <div className="col-span-5 lg:col-span-4 flex flex-col max-h-[calc(100dvh-50px)] md:max-h-[calc(100dvh-100px)]">
          <div
            id="reading-header"
            className="flex items-center justify-between mb-2 md:mb-4"
          >
            <h2 className="text-lg md:text-2xl font-semibold text-orange-950 inline-block">
              <span>
                {reading && format(new Date(reading.createdAt), "MMMM d, yyyy")}{" "}
                -{" "}
              </span>
              <span className="hidden md:inline">{reading.card.name}</span>
            </h2>
            <div className="flex items-center gap-2">
              <ToggleFavorite readingId={reading?.id} />
              <div className="lg:hidden">
                <Drawer>
                  <DrawerTrigger asChild>
                    <Button
                      variant="outline"
                      className="border-primary-darker text-primary-foreground"
                      size="icon"
                    >
                      <Eye className="h-4 w-4" />
                    </Button>
                  </DrawerTrigger>
                  <DrawerContent>
                    <div className="mx-auto w-full max-w-sm px-2 pb-2">
                      {reading && <CardContent reading={reading} />}
                    </div>
                  </DrawerContent>
                </Drawer>
              </div>
            </div>
          </div>
          <div
            className="scrollbar-custom relative rounded-lg p-4 space-y-12 flex-auto overflow-y-auto border border-primary-darker"
            ref={chatContainerRef}
          >
            {isLoading ? (
              <div className="relative">
                <span
                  className={cn(
                    "text-xs uppercase absolute -top-5 text-orange-950/40 left-6"
                  )}
                >
                  {guideFirstName}
                </span>
                <Skeleton className="w-7/12 h-40 mt-6 bg-orange-950/10" />
              </div>
            ) : (
              reading?.conversation.messages.map(
                (message: Message, index: number) => (
                  <div
                    key={index}
                    className={cn("mb-4 mt-6  flex", {
                      "justify-end": message.role === "user",
                      "justify-start": message.role === "assistant"
                    })}
                  >
                    <div
                      className={cn(
                        "max-w-[600px] relative rounded-2xl shadow-inner shadow-black/10 border border-primary-foreground/10",
                        {
                          "bg-primary-darker rounded-br-sm":
                            message.role === "user",
                          "bg-primary-dark rounded-bl-sm":
                            message.role === "assistant"
                        }
                      )}
                    >
                      <div className="absolute inset-0 rounded-2xl overflow-hidden opacity-10">
                        <div
                          className={cn(
                            "absolute w-3/4 aspect-square blur-3xl -bottom-24 bg-gradient-to-b from-transparent to-black/50 bg-orange-600 rounded-full",
                            {
                              "-left-24": message.role === "user",
                              "-right-24 ": message.role === "assistant"
                            }
                          )}
                        />
                      </div>
                      <Textures type="paper-1" className="" />
                      <div className="relative z-10 px-6 py-4 rounded-2xl ">
                        <span
                          className={cn(
                            "text-xs uppercase absolute -top-5 text-primary-foreground",
                            {
                              "right-6": message.role === "user",
                              "left-6": message.role === "assistant"
                            }
                          )}
                        >
                          {message.role === "user" ? "You" : guideFirstName}
                        </span>
                        <p
                          className={cn("whitespace-pre-line py-2", {
                            "text-orange-950": message.role === "user",
                            "text-yellow-950": message.role === "assistant"
                          })}
                        >
                          {message.content}
                        </p>
                        {/* <span>{message?.createdAt}</span> */}
                      </div>
                    </div>
                  </div>
                )
              )
            )}
            {sendMessageMutation.isPending && (
              <div className="flex items-center space-x-2 mb-4 ml-4">
                <motion.div
                  className="w-2 h-2 bg-gray-500 rounded-full"
                  animate={{ scale: [1, 1.2, 1] }}
                  transition={{ duration: 0.5, repeat: Infinity }}
                />
                <motion.div
                  className="w-2 h-2 bg-gray-500 rounded-full"
                  animate={{ scale: [1, 1.2, 1] }}
                  transition={{
                    duration: 0.5,
                    repeat: Infinity,
                    delay: 0.1
                  }}
                />
                <motion.div
                  className="w-2 h-2 bg-gray-500 rounded-full"
                  animate={{ scale: [1, 1.2, 1] }}
                  transition={{
                    duration: 0.5,
                    repeat: Infinity,
                    delay: 0.2
                  }}
                />
              </div>
            )}
          </div>

          <div className="flex my-4 items-center shrink-0" id="chat-input">
            <textarea
              ref={textareaRef}
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  handleSendMessage();
                }
              }}
              rows={1}
              className="flex-grow px-5 max-h-36 mr-2 py-3 border rounded-l-lg resize-none overflow-hidden"
              placeholder="Talk with your guide..."
            />
            <PushableButton
              text="Send"
              onClick={handleSendMessage}
              className="rounded-l-none"
              // disabled={sendMessageMutation.isPending}
            />
          </div>
        </div>
        <div className="col-span-1 hidden lg:block">
          <div className="w-full flex flex-col justify-start items-center">
            {reading && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <CardContent reading={reading} />
              </motion.div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

interface CardProps {
  card: CardWithReversed;
}

export const Card: React.FC<CardProps> = ({ card }) => {
  return (
    <div className="max-w-[250px] mx-auto aspect-[400/700] rounded-lg lg:rounded-2xl p-4 pb-[calc(36px+1rem)] bg-[#f4e7d8] border border-black border-opacity-40 relative shadow-lg z-10">
      <div className="relative w-full h-full">
        {/* Card Number */}
        <div className="flex items-center absolute w-full justify-center z-50 top-0">
          <span className="absolute flex items-center justify-center font-rosarivo text-xs border border-t-0 border-black/60 bg-[#f4e7d8] z-50 font-left-0 mx-4 w-8 h-4 mt-4 rounded-br-full rounded-bl-full">
            <span className="z-10 text-xs -mt-2">{card.cardNumber}</span>
          </span>
        </div>

        {/* Card Image */}
        <div className="overflow-hidden relative w-full h-full rounded-t-md">
          <img
            src={card.imageUrl || ""}
            className={cn(
              "object-cover rounded-md rounded-b-none w-full h-full pointer-events-none border border-black/40",
              card.isReversed && "rotate-180"
            )}
            alt={card.name}
          />
        </div>

        {/* Card Name */}
        <div className="relative inset-x-0 bottom-0 bg-[#f4e7d8] border border-t-0 text-center border-black/40 rounded-b-md p-1">
          <span className="font-rosarivo text-base lg:text-lg">
            {card.name}
          </span>
        </div>

        {/* Texture Overlay */}
        <div className="absolute z-10 inset-0 bg-[url('/texture.jpg')] bg-cover mix-blend-multiply pointer-events-none"></div>
      </div>
    </div>
  );
};

const CardContent = ({ reading }: { reading: Reading }) => (
  <>
    <div className="relative">
      <img
        src={reading.card.imageUrl}
        alt={reading.card.name}
        className={cn(
          "w-full h-full object-cover rounded-lg inline-block border-primary-foreground/25 border",
          reading.isReversed && "rotate-180"
        )}
      />
      <div className="absolute inset-0 shadow-inner shadow-black/50 rounded-lg" />
    </div>
    {/* <Card card={reading.card} /> */}
    <div className="hidden md:flex flex-col gap-2 items-center justify-center mt-6">
      <Badge className="bg-primary border-primary-darker">
        {reading.card.arcanaType}
      </Badge>
      <Badge className="bg-primary border-primary-darker">
        {reading.card.suit}
      </Badge>
    </div>
    <hr className="w-1/2 mx-auto hidden md:block border-primary-darker my-3" />
    <ul className="space-y-2 hidden md:flex flex-col items-center">
      {reading.card.keywords.map((badge: string) => (
        <li className="shrink-0 mt-1 mr-2" key={badge}>
          <Badge variant="default" className="bg-primary border-primary-darker">
            {badge}
          </Badge>
        </li>
      ))}
    </ul>
  </>
);

export default ReadingDetail;
