import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { verifyEmail } from "@/utils/api";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "@/components/ui/use-toast";
import Layout from "@/components/layout";
import { motion } from "framer-motion";

export default function VerifyPage() {
  const { token } = useParams<{ token: string }>();
  const navigate = useNavigate();
  const [isVerifying, setIsVerifying] = useState(true);
  const queryClient = useQueryClient();

  useEffect(() => {
    const verify = async () => {
      try {
        if (!token) {
          toast({
            title: "Error",
            description: "Invalid verification link",
            variant: "destructive"
          });
          navigate("/login");
          return;
        }

        await verifyEmail(token);

        // Invalidate and refetch
        await queryClient.invalidateQueries({ queryKey: ["currentUser"] });

        toast({
          title: "Success!",
          description:
            "Your email has been verified. You received 5 free message credits!"
        });

        // Redirect to dashboard after a short delay
        setTimeout(() => {
          navigate("/daily");
        }, 2000);
      } catch (error) {
        console.error("Verification error:", error);
        toast({
          title: "Error",
          description:
            "Failed to verify email. Please try again or contact support.",
          variant: "destructive"
        });
        navigate("/login");
      } finally {
        setIsVerifying(false);
      }
    };

    verify();
  }, [token, navigate, queryClient]);

  return (
    <Layout>
      <div className="flex flex-col items-center justify-center min-h-[calc(100vh-200px)]">
        {isVerifying ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="text-center"
          >
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-rose-600 mb-4"></div>
            <p className="text-primary-foreground text-lg">
              Verifying your email...
            </p>
          </motion.div>
        ) : (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-center"
          >
            <div className="text-rose-600 mb-4">
              <svg
                className="h-12 w-12 mx-auto"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
            <h2 className="text-2xl font-semibold text-primary-foreground mb-2">
              Email Verified!
            </h2>
            <p className="text-primary-foreground/80">
              Redirecting you to the dashboard...
            </p>
          </motion.div>
        )}
      </div>
    </Layout>
  );
}
