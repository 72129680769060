import { motion, useInView } from "framer-motion";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from "@/components/ui/accordion";
import { useRef } from "react";
const faqs = [
  {
    id: "credits-explained",
    question: "How do credits work?",
    answer:
      "Credits are used for sending messages to your guide. Each message costs one credit. Want to try it out? You can start for free with 5 free credits after verifying your email. Daily card draws are always free and include a basic interpretation. For deeper conversations about your readings, you'll need credits. Bought a package and used up your credits? No problem! You can purchase more credits at any time."
  },
  {
    id: "credit-packages",
    question: "What do I get with 222 credits?",
    answer:
      "With 222 credits ($19.99), you can have 222 exchanges with your guide. Use them to explore card meanings, ask follow-up questions about readings, or seek deeper insights. Credits never expire, so you can use them at your own pace."
  },
  {
    id: "daily-draws",
    question: "Are daily card draws really free?",
    answer:
      "Yes! You can draw a card daily and receive a basic interpretation completely free. To explore deeper meanings or ask specific questions about your reading, you'll use credits to chat with your guide."
  },
  {
    id: "ai-guides",
    question: "How do the different AI guides work?",
    answer:
      "Choose from four unique guides: Luna, Sage, Thomas, and Maya. Each brings their own perspective and style to Tarot interpretation. You can switch between guides anytime, and your credits work with any guide you choose."
  },
  {
    id: "physical-vs-digital",
    question: "Can I use my physical deck?",
    answer:
      "Absolutely! You can either draw cards digitally or log readings from your physical deck. Record the cards you pulled and use your credits to discuss them with your guide. Your practice, your choice."
  },
  {
    id: "accuracy-ai-readings",
    question: "How accurate are AI-powered tarot readings?",
    answer:
      "Your Unique Tarot is currently powered by Anthropic Claude Sonnet 3.5, one of the most powerful Large Language Models. It's trained on a vast amount of Tarot knowledge and can provide accurate interpretations of the cards. However, it's important to remember that AI readings are not a substitute for in-person readings. They can't replicate the intuitive energy of in-person readings, but they excel at helping you learn card meanings, explore interpretations, and deepen your own practice. I like to see it as a supplemental tool to my Tarot Practice, not a replacement."
  },
  {
    id: "future-prediction",
    question: "Can Tarot predict the future?",
    answer:
      "Rather than predicting the future, Tarot taps into something our rational modern world often neglects: the power of images, symbolism and archetypal wisdom. Its ancient imagery speaks to the imagination—a faculty that's been diminished in our literal-minded age, yet remains vital for deeper self-understanding. When we engage with Tarot's rich symbolic language, we're not fortune-telling but rather reflecting on universal patterns of human experience that illuminate our present journey and conscious choices."
  },

  {
    id: "privacy-security",
    question: "How secure are my readings and conversations?",
    answer:
      "All readings and conversations are encrypted and stored securely following the highest standards."
  },
  {
    id: "credit-expiration",
    question: "Do credits expire?",
    answer: "No, your purchased credits don't have an expiration date."
  }
];

const FAQSection = () => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  return (
    <div className="max-w-7xl mx-auto lg:mt-32 mt-16">
      <motion.div className="mx-auto max-w-3xl text-primary-foreground text-center lg:pt-24 lg:mt-16 relative">
        <div className="w-full flex justify-center mt-12">
          {/* Rotating moon in a loop */}
          <motion.img
            src="/card-assets/star-0.png"
            alt="Diamond"
            initial={{ opacity: 0, y: 0, rotate: 0 }}
            ref={ref}
            animate={
              isInView
                ? {
                    opacity: 1,
                    y: [0, 10, 0],
                    rotate: [0, -360, 0]
                  }
                : { opacity: 0, y: -10, rotate: 0 }
            }
            transition={{
              opacity: { duration: 1, delay: 1 },
              rotate: {
                repeat: Infinity,
                repeatType: "loop",
                duration: 60,
                ease: "easeInOut"
              }
            }}
            className="lg:w-16 lg:h-16 w-12 h-12 object-contain -top-2 lg:-top-6 relative origin-center"
          />
        </div>
        <h2 className="lg:text-3xl text-2xl font-bold tracking-tight lg:leading-loose text-primary-foreground sm:text-4xl sm:leading-[3.5rem]">
          Frequently Asked Questions
        </h2>
        <p className="mt-2 lg:text-lg lg:leading-8 text-primary-foreground font-rosarivo mx-auto max-w-[450px] px-4">
          Get Tarot readings and guidance on your terms. Purchase credits and
          use them whenever you need. No monthly fees, no commitments.
        </p>
      </motion.div>
      <div className="max-w-3xl mx-auto mt-12 px-4">
        <Accordion type="single" collapsible>
          {faqs.map((faq) => (
            <AccordionItem value={faq.id}>
              <AccordionTrigger className="py-6">
                {faq.question}
              </AccordionTrigger>
              <AccordionContent>{faq.answer}</AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default FAQSection;
