import Layout from "@/components/layout";
import { fetchAllReadings } from "@/utils/api";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { Card as CardType } from "@/types/Card";
import { CardLoader } from "@/components/CardLoader";
import { Card } from "./ReadingDetail";
import { cn } from "@/lib/utils";

interface Reading {
  id: number;
  type: "daily" | "custom";
  card: CardType;
  createdAt: string;
  isReversed: boolean;
  guide: {
    id: number;
    name: string;
    description: string;
  };
  interpretation: string;
}

interface ReadingsResponse {
  readings: Reading[];
  pagination: {
    total: number;
    limit: number;
    offset: number;
  };
}

const ReadingOverview: React.FC = () => {
  const { data, isLoading, isFetching } = useQuery<ReadingsResponse>({
    queryKey: ["readings"],
    queryFn: async () => {
      const response = await fetchAllReadings();
      return response as unknown as ReadingsResponse;
    }
  });

  if (isLoading && !isFetching) {
    return (
      <Layout>
        <CardLoader loadingText="Loading your readings..." />
      </Layout>
    );
  }

  console.log(data?.readings);

  return (
    <Layout disablePadding>
      <div className="relative h-full md:pb-8">
        <div>
          <h1 className="text-3xl font-bold text-orange-950 mb-6 text-center">
            Your Readings
          </h1>
          <hr className="border-orange-950/10 mb-10" />

          {data?.readings.length === 0 ? (
            <div className="text-center text-gray-600 mt-8">
              <p>You haven't done any readings yet.</p>
            </div>
          ) : (
            <div className="px-4 sm:px-8 h-full pb-8 max-w-5xl mx-auto">
              <div className="space-y-12">
                {data?.readings.map((reading, index) => (
                  <Link
                    key={reading.id}
                    to={`/readings/${reading.id}`}
                    className="block group"
                  >
                    <div
                      className={`flex items-center gap-8 group relative ${
                        index % 2 === 0 ? "flex-row" : "flex-row-reverse"
                      }`}
                    >
                      <div
                        className={cn(
                          "my-16 absolute inset-0 group-hover:opacity-100 opacity-50 transition-opacity duration-300 from-orange-950/20 to-transparent rounded-2xl shadow-inner",
                          index % 2 === 0
                            ? "bg-gradient-to-l ml-32"
                            : "bg-gradient-to-r mr-32"
                        )}
                      />
                      <div className="w-1/3 flex items-start justify-center">
                        {/* <div className="flex items-center justify-center mb-4">
                          <span className="text-primary-foreground text-xl text-center inline-block pt-2 pb-1 px-6 border border-primary-darker mx-auto bg-background-dark rounded-t-lg">
                            {format(new Date(reading.createdAt), "MMM d")}
                          </span>
                        </div> */}
                        <div className={cn(reading.isReversed && "rotate-180")}>
                          <Card card={reading.card} />
                        </div>
                      </div>

                      <div className="w-2/3 space-y-4 px-8">
                        <h2 className="text-2xl font-semibold text-primary-foreground">
                          {format(new Date(reading.createdAt), "MMM d")} -{" "}
                          {reading.card.name}
                        </h2>
                        <div className="hidden lg:block space-y-2">
                          <p className="text-sm text-orange-950/60 font-medium">
                            Interpretation by {reading.guide.name}
                          </p>
                          <p className="text-primary-foreground line-clamp-1 sm:line-clamp-2 lg:line-clamp-4">
                            {reading.interpretation}
                          </p>
                        </div>
                        <div className="text-sm text-orange-950/60">
                          <span className="inline-flex items-center gap-1">
                            View full reading
                            <svg
                              className="w-4 h-4"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M9 5l7 7-7 7"
                              />
                            </svg>
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default ReadingOverview;
