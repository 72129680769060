import { motion, useInView } from "framer-motion";
import { Textures } from "./Textures";
import { useRef, useState } from "react";
import { Check } from "lucide-react";
import { cn } from "@/lib/utils";
import { PushableButton } from "./PushableButton";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle
} from "./ui/dialog";
import { Input } from "./ui/input";
import axios from "axios";
import { useToast } from "./ui/use-toast";

const PricingSection = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, margin: "-100px" });
  const [isWaitlistOpen, setIsWaitlistOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { toast } = useToast();

  const handleWaitlistSubmit = async () => {
    if (!email) return;

    setIsSubmitting(true);
    try {
      await axios.post("http://localhost:8080/api/waitlist", {
        email,
        source: "pricing_section"
      });
      toast({
        title: "Success!",
        description:
          "Thank you for joining our waitlist! We'll be in touch soon."
      });
      setIsWaitlistOpen(false);
      setEmail("");
    } catch (_err) {
      toast({
        title: "Error",
        description: "Something went wrong. Please try again.",
        variant: "destructive"
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div ref={ref} id="landing-pricing" className="relative">
      {/* <Textures type="paper-1" /> */}
      {/* <TornDivider className="bottom-48" /> */}
      <motion.div className="mx-auto max-w-3xl text-primary-foreground text-center lg:pt-24 mt-16 relative">
        <div className="w-full flex justify-center mt-12">
          {/* Rotating moon in a loop */}
          <motion.img
            src="/gemstones/diamond.png"
            alt="Diamond"
            initial={{ opacity: 0, y: 0 }}
            animate={
              isInView
                ? {
                    opacity: 1,
                    y: [0, 10, 0]
                  }
                : { opacity: 0, y: -10 }
            }
            transition={{
              opacity: { duration: 1, delay: 1 },
              y: {
                repeat: Infinity,
                repeatType: "loop",
                duration: 15,
                ease: "easeInOut"
              }
            }}
            className="w-16 h-16 object-contain -top-6 relative origin-center"
          />
        </div>
        <h2 className="text-2xl lg:text-3xl text font-bold tracking-tight lg:leading-loose text-primary-foreground sm:text-4xl sm:leading-[3.5rem] px-4">
          Pay As You Go, <br />
          No Subscription
        </h2>
        <p className="mt-2 text-lg leading-8 text-primary-foreground font-rosarivo mx-auto max-w-[450px] px-4">
          Get Tarot readings and guidance on your terms. Send messages to your
          guide for 1 credit each - use them at your own pace. No monthly fees.
        </p>
      </motion.div>
      <div className="mx-auto max-w-7xl">
        <div className="isolate mx-auto lg:pt-24 pt-16 grid max-w-md grid-cols-1 gap-y-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-6 relative items-center px-2">
          {/* {tiers.map((tier, tierIdx) => ( */}
          {/* Amethyst Pack */}
          <motion.div
            style={{
              transformStyle: "preserve-3d"
            }}
            animate={{
              transform: "perspective(1000px) rotate3d(0, 0, 0, 6deg)",
              scale: 1
            }}
            transition={{
              duration: 1,
              ease: "easeOut"
            }}
            className={cn(
              "flex flex-col justify-between rounded-2xl p-8 relative border border-primary-foreground/20 lg:z-10 shadow-2xl bg-primary lg:col-span-2 lg:col-start-2 mt-20 lg:mt-0"
            )}
          >
            <div className="absolute inset-x-4 inset-y-4 border-2 border-primary-foreground/20 rounded-xl"></div>
            <Textures type="paper-1" />
            <div className="relative z-10">
              <div
                className={cn("flex items-center justify-center -mt-40 mb-6")}
              >
                <motion.div
                  animate={{
                    rotate: [0, 2, 0, -2, 0],
                    scale: [1, 1.05, 1],
                    y: [0, 10, 0]
                  }}
                  transition={{
                    rotate: {
                      repeat: Infinity,
                      repeatType: "loop",
                      duration: 15,
                      ease: "easeInOut"
                    },
                    scale: {
                      repeat: Infinity,
                      repeatType: "loop",
                      duration: 15,
                      ease: "easeInOut"
                    },
                    y: {
                      repeat: Infinity,
                      repeatType: "loop",
                      duration: 15,
                      ease: "easeInOut",
                      delay: 5
                    }
                  }}
                  className={cn(
                    "aspect-square bg-contain bg-center bg-no-repeat origin-center",
                    "w-48 h-48"
                  )}
                  style={{
                    backgroundImage: `url('/gemstones/amethyst-2.png')`
                  }}
                />
              </div>
              <h3
                className={cn(
                  "text-primary-foreground relative z-10",
                  "font-semibold mb-2 text-center",
                  "text-3xl leading-normal"
                )}
              >
                Crystal Clear <br /> Tarot Guidance
              </h3>

              <p className="mt-4 text-primary-foreground text-center max-w-[400px] mx-auto">
                Establish a strong daily practise with a daily reading, learn
                naturally through conversation with your own guide, and develop
                your own understanding, at your own pace.
              </p>
              <div className="flex items-center justify-center">
                <ul
                  role="list"
                  className="mt-8 space-y-3 text-primary-foreground"
                >
                  <li className="flex gap-3">
                    <Check
                      aria-hidden="true"
                      className="h-6 w-5 flex-none text-rose-700"
                    />
                    <span>Daily Reading & Interpretation</span>
                  </li>
                  <li className="flex gap-3">
                    <Check
                      aria-hidden="true"
                      className="h-6 w-5 flex-none text-rose-700"
                    />
                    <span>222 Credits to Explore Your Readings</span>
                  </li>
                  <li className="flex gap-3">
                    <Check
                      aria-hidden="true"
                      className="h-6 w-5 flex-none text-rose-700"
                    />
                    <span>Personal AI Tarot Guide 24/7</span>
                  </li>
                  <li className="flex gap-3">
                    <Check
                      aria-hidden="true"
                      className="h-6 w-5 flex-none text-rose-700"
                    />
                    <span>Deep Dive into Card Meanings</span>
                  </li>
                  <li className="flex gap-3">
                    <Check
                      aria-hidden="true"
                      className="h-6 w-5 flex-none text-rose-700"
                    />
                    <span>Access Anywhere, Anytime </span>
                  </li>
                </ul>
              </div>
              <div className="flex flex-col items-center justify-center gap-4 mt-5 mb-4 relative">
                {/* Rotating Background Blob */}
                <div className="absolute inset-0 flex items-center justify-center blur">
                  <motion.div
                    className={cn(
                      "-z-10 absolute",
                      "w-52 h-52",
                      "bg-yellow-600/50"
                    )}
                    initial={{ opacity: 0 }}
                    animate={{
                      opacity: [0.1, 0.2, 0.1],
                      scale: [0.7, 0.8, 0.7],
                      borderRadius: [
                        "30% 70% 70% 30% / 30% 30% 70% 70%",
                        "60% 40% 30% 70% / 60% 30% 70% 40%",
                        "30% 60% 70% 40% / 50% 60% 30% 60%",
                        "30% 70% 70% 30% / 30% 30% 70% 70%"
                      ]
                    }}
                    transition={{
                      duration: 16,
                      repeat: Infinity,
                      repeatType: "mirror",
                      ease: "easeInOut",
                      delay: 1.6,
                      opacity: {
                        repeat: 1,
                        duration: 8
                      }
                    }}
                  />
                </div>
                <div className="flex flex-col items-center justify-center text-primary-foreground text-4xl font-semibold relative drop-shadow-xl text-center space-y-4 mt-4 mb-6">
                  <span>
                    <span className="text-5xl relative">
                      <span className="relative z-10 text-rose-600">222</span>
                      <span className="absolute inset-0 text-rose-600/75 top-2 blur-lg">
                        222
                      </span>
                    </span>
                    <span className="text-4xl"> Credits</span>
                  </span>
                  {/* <span className="text-primary-foreground text-3xl ml-2"> */}
                  <span className="text-3xl font-semibold tracking-tight text-primary-foreground block">
                    For $19.99
                  </span>
                </div>
              </div>
            </div>
            <PushableButton
              text={"Begin Your Journey"}
              className={cn("mx-auto mb-6")}
              onClick={() => setIsWaitlistOpen(true)}
            />
          </motion.div>
          <motion.div
            style={{
              transformStyle: "preserve-3d"
            }}
            animate={{
              transform: "perspective(1000px) rotate3d(-1, 0, 0, 0deg)",
              scale: 0.5
            }}
            transition={{
              duration: 1,
              ease: "easeOut"
            }}
            className={cn(
              "flex flex-col justify-between rounded-2xl p-8 relative border border-primary-foreground/20 lg:col-span-2",
              "lg:my-8 shadow-lg mt-8 lg:mt-0",
              "lg:-left-12 px-16 bg-primary-dark"
            )}
          >
            <div className="absolute inset-x-4 inset-y-4 border-2 border-primary-foreground/20 rounded-xl"></div>
            <Textures type="paper-1" />
            <div className="relative z-10">
              <div
                className={cn(
                  "flex items-center justify-center mb-4",
                  "-mt-20"
                )}
              >
                <motion.div
                  animate={{
                    rotate: 0,
                    y: [0, 10, 0]
                  }}
                  transition={{
                    rotate: {
                      repeat: Infinity,
                      repeatType: "loop",
                      duration: 15,
                      ease: "easeInOut"
                    },
                    y: {
                      repeat: Infinity,
                      repeatType: "loop",
                      duration: 15,
                      ease: "easeInOut"
                    }
                  }}
                  className={cn(
                    "aspect-square bg-contain bg-center bg-no-repeat origin-center",
                    "w-24 h-24"
                  )}
                  style={{
                    backgroundImage: `url('/gemstones/moonstone.png')`
                  }}
                />
              </div>
              <h3
                className={cn(
                  "text-primary-foreground relative z-10",
                  "font-semibold mb-2 text-center",
                  "text-2xl mt-6"
                )}
              >
                Tarot Essentials
              </h3>

              <p className="mt-2 text-primary-foreground text-center max-w-[350px] mx-auto lg:text-sm/6">
                Free daily card readings + get 5 free credits when you verify
                your email - start chatting with your guide and explore your
                first readings together.
              </p>
              <div className="flex items-center justify-center">
                <ul
                  role="list"
                  className="mt-8 space-y-3 lg:text-sm/6 text-primary-foreground"
                >
                  {[
                    "Daily Reading & Interpretation",
                    "5 Free Credits",
                    "Access to Basic Card Meanings"
                  ].map((feature) => (
                    <li key={feature} className="flex gap-x-3">
                      <Check
                        aria-hidden="true"
                        className="h-6 w-5 flex-none text-rose-700"
                      />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="flex items-center justify-center">
                <PushableButton
                  text={"Get Started For Free"}
                  size="small"
                  onClick={() => setIsWaitlistOpen(true)}
                  className={cn("mx-auto mt-8 mb-4 grayscale hover:grayscale")}
                />
              </div>
            </div>
          </motion.div>
        </div>
      </div>

      <Dialog open={isWaitlistOpen} onOpenChange={setIsWaitlistOpen}>
        <DialogContent className="sm:max-w-[425px] bg-primary text-primary-foreground">
          <DialogHeader>
            <DialogTitle>Join Our Waitlist</DialogTitle>
            <DialogDescription className="text-primary-foreground/80">
              We're currently in closed beta. Leave your email and we'll notify
              you when a spot opens up.
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <Input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="bg-primary-dark"
            />
            <PushableButton
              onClick={handleWaitlistSubmit}
              disabled={isSubmitting}
              text={isSubmitting ? "Submitting..." : "Join Waitlist"}
              className="w-full"
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PricingSection;
