import Logo from "./Logo";

export default function Footer() {
  return (
    <footer
      aria-labelledby="footer-heading"
      className="font-rosarivo lg:mt-32 relative"
    >
      <div className="relative z-10">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
          <div
          // className="grid grid-cols-1 md:grid-cols-1 gap-8 md:gap-16 justify-between"
          >
            <div className="space-y-8 mb-36 flex flex-col items-center text-center relative">
              <a
                href="/"
                className="w-full items-center justify-center p-1.5 flex space-x-4"
              >
                <Logo textSize="large" />
              </a>

              <p className="text-sm leading-6 text-primary-foreground max-w-[290px] border-b border-primary-foreground/10 pb-8">
                A modern companion for self-reflection through the timeless
                language of Tarot
              </p>

              <p className="text-sm leading-6 text-primary-foreground max-w-[290px]">
                Tarot card illustrations by <br />
                <a
                  href="https://en.wikipedia.org/wiki/Pamela_Colman_Smith"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline"
                >
                  Pamela Colman Smith
                </a>
              </p>
              <p className="text-sm leading-6 text-primary-foreground max-w-[290px]  border-b border-primary-foreground/10 pb-8">
                Crafted with ❤ by <br />
                <a
                  href="https://www.twitter.com/aiiidenfreeman"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline"
                >
                  Aiden Freeman
                </a>
              </p>
              <p className="text-sm leading-6 text-primary-foreground max-w-[290px] flex flex-col gap-y-2  border-b border-primary-foreground/10 pb-8">
                <a href="/contact" className="underline">
                  Contact
                </a>
                <a href="/privacy-policy" className="underline">
                  Privacy Policy
                </a>
                <a href="/terms-of-service" className="underline">
                  Terms of Service
                </a>
                <a href="/refund-policy" className="underline">
                  Refund Policy
                </a>
              </p>
              <div className="absolute -bottom-16 flex items-center justify-center">
                <span className="absolute text-3xl text-primary-foreground bg-blend-screen-dodge opacity-20">
                  魂
                </span>
                <span className="absolute text-3xl text-black/50 blur-[1px] bg-blend-screen-dodge opacity-20">
                  魂
                </span>
              </div>
            </div>
          </div>

          <div className="mt-16 border-t border-gray-900/10 pt-8 sm:mt-20 lg:mt-24 relative">
            <p className="text-xs leading-5 text-primary-foreground text-center">
              &copy; {new Date().getFullYear()} Your Unique Tarot, Inc. All
              rights reserved.
            </p>
          </div>
        </div>
      </div>
      <div className="absolute inset-0 bg-gradient-to-t from-orange-950 opacity-10 to-transparent h-2/4 top-2/4" />
    </footer>
  );
}
