import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Card, CardContent } from "@/components/ui/card";
import { User, Check } from "lucide-react";
import { useEffect, useState } from "react";
import { z } from "zod";
import Navbar from "@/components/navbar";
import { useSearchParams } from "react-router-dom";
import { TarotFormField } from "@/components/TarotFormField";
import { Form } from "@/components/ui/form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { PushableButton } from "@/components/PushableButton";
import { guides } from "./Guides";
import { cn } from "@/lib/utils";
import { useQueryClient } from "@tanstack/react-query";
import { registerUser } from "@/utils/api";
import { useUserStore } from "@/stores/useUserStore";
import { useMutation } from "@tanstack/react-query";
import { Textures } from "@/components/Textures";
import {
  PaymentElement,
  useStripe,
  useElements,
  Elements
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription
} from "@/components/ui/dialog";

// Initialize Stripe
const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);

const formSchema = z.object({
  name: z.string().min(1, "Name is required"),
  email: z.string().email("Invalid email address"),
  password: z.string().min(8, "Password must be at least 8 characters long"),
  preferredGuideId: z.number().min(1, "Please select a guide"),
  packageType: z.enum(["free", "premium"], {
    required_error: "Please select a package"
  })
});

type FormData = z.infer<typeof formSchema>;

// Payment form wrapper
const PaymentFormWrapper = ({
  onPaymentSuccess,
  onClose
}: {
  onPaymentSuccess: () => void;
  onClose: () => void;
}) => {
  const [clientSecret, setClientSecret] = useState<string>();

  useEffect(() => {
    fetch(
      `${import.meta.env.VITE_API_URL}/api/payments/create-registration-intent`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        }
      }
    )
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret))
      .catch((err) => {
        console.error("Error creating payment intent:", err);
        onClose(); // Close modal on error
      });
  }, []);

  if (!clientSecret) {
    return (
      <div className="flex items-center justify-center p-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-rose-600" />
      </div>
    );
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret,
        appearance: {
          theme: "stripe"
        }
      }}
    >
      <PaymentForm onPaymentSuccess={onPaymentSuccess} />
    </Elements>
  );
};

// Payment form component
const PaymentForm = ({
  onPaymentSuccess
}: {
  onPaymentSuccess: () => void;
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);

    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        redirect: "if_required",
        confirmParams: {
          payment_method_data: {
            billing_details: {} // This ensures 3D Secure works properly
          }
        }
      });

      if (error) {
        setErrorMessage(error.message);
        setIsProcessing(false);
      } else if (paymentIntent && paymentIntent.status === "succeeded") {
        onPaymentSuccess();
      }
    } catch (e) {
      setErrorMessage("An unexpected error occurred.");
      setIsProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mt-4">
      <PaymentElement />
      {errorMessage && (
        <div className="mt-4 text-red-600 text-sm">{errorMessage}</div>
      )}
      <button
        type="submit"
        disabled={!stripe || isProcessing}
        className={cn(
          "mt-6 w-full bg-rose-600 text-white py-2 px-4 rounded-md",
          "hover:bg-rose-700 disabled:opacity-50 disabled:cursor-not-allowed",
          "transition-all duration-200 ease-in-out"
        )}
      >
        {isProcessing ? "Processing..." : "Complete Payment"}
      </button>
    </form>
  );
};

export default function RegisterPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const { setUser } = useUserStore();

  const form = useForm<FormData>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      email: "",
      password: "",
      preferredGuideId: 1,
      packageType: "premium"
    }
  });

  useEffect(() => {
    // Pre-fill form fields from URL parameters
    const name = searchParams.get("name");
    const email = searchParams.get("email");

    if (name) form.setValue("name", name);
    if (email) form.setValue("email", email);
  }, [searchParams, form]);

  const mutation = useMutation({
    mutationFn: registerUser,
    onSuccess: (data) => {
      localStorage.setItem("token", data.token);
      setUser(data.user);
      queryClient.invalidateQueries({ queryKey: ["currentUser"] });
      navigate("/daily", {
        state: {
          message: "Please check your email to verify your account."
        }
      });
    },
    onError: (error) => {
      console.error("Error:", error);
      // You might want to add error handling here
    }
  });

  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const handlePaymentSuccess = () => {
    // After successful payment, proceed with registration with premium package
    const values = form.getValues();
    mutation.mutate({
      ...values,
      initialCredits: 222 // Premium users get 222 credits immediately
    });
  };

  const onSubmit = async (values: FormData) => {
    if (values.packageType === "premium") {
      // Show payment modal for premium users
      setShowPaymentModal(true);
    } else {
      // For free tier, proceed with registration (credits will be added after email verification)
      mutation.mutate({
        ...values,
        initialCredits: 0
      });
    }
  };

  const handleGuideSelect = (guideId: number) => {
    form.setValue("preferredGuideId", guideId);
  };

  const selectedGuide = form.watch("preferredGuideId");

  return (
    <>
      <Navbar />
      <div className="flex items-center justify-center min-h-[calc(100vh-76px)] mt-10 pb-10 lg:pb-24">
        <div className="flex flex-1 justify-start content-start flex-wrap max-w-4xl relative">
          <motion.img
            initial={{ opacity: 0, rotate: 10, y: 0, x: -50 }}
            animate={{
              opacity: 1,
              rotate: [0, 5, 0],
              y: [0, 50, 0],
              x: 0
            }}
            transition={{
              duration: 0.5,
              delay: 3.5,
              rotate: {
                duration: 10,
                repeat: Infinity,
                ease: "easeInOut"
              },
              y: {
                duration: 20,
                repeat: Infinity,
                ease: "easeInOut"
              }
            }}
            src="/card-assets/pents-01.png"
            className="w-32 absolute bottom-[5%] -right-24"
          />
          <motion.img
            initial={{ opacity: 0, rotate: 10, y: 0, x: -50 }}
            animate={{
              opacity: 3,
              rotate: [0, 5, 0],
              y: [0, 50, 0],
              x: 0
            }}
            transition={{
              duration: 0.5,
              delay: 2,
              rotate: {
                duration: 10,
                repeat: Infinity,
                ease: "easeInOut"
              },
              y: {
                duration: 25,
                repeat: Infinity,
                ease: "easeInOut"
              }
            }}
            src="/card-assets/sword-01.png"
            className="w-28 absolute -top-10 -right-20"
          />
          <motion.img
            initial={{ opacity: 0, rotate: -10, y: 0, x: 20 }}
            animate={{
              opacity: 1,
              rotate: [0, -5, 0],
              y: [0, 40, 0],
              x: 0
            }}
            transition={{
              duration: 0.5,
              delay: 2.5,
              rotate: {
                duration: 10,
                repeat: Infinity,
                ease: "easeInOut"
              },
              y: {
                duration: 20,
                repeat: Infinity,
                ease: "easeInOut"
              }
            }}
            src="/card-assets/wands-01.png"
            className="w-32 absolute -top-10 -left-24"
          />
          <motion.img
            initial={{ opacity: 0, rotate: -10, y: 0, x: 20 }}
            animate={{
              opacity: 1,
              rotate: [0, -5, 0],
              y: [0, 40, 0],
              x: 0
            }}
            transition={{
              duration: 0.5,
              delay: 3,
              rotate: {
                duration: 30,
                repeat: Infinity,
                ease: "easeInOut"
              },
              y: {
                duration: 26,
                repeat: Infinity,
                ease: "easeInOut"
              }
            }}
            src="/card-assets/cups-01.png"
            className="w-32 absolute bottom-2 -left-24"
          />
          <Card className="w-full overflow-auto relative z-10 bg-background border border-primary-foreground/20 rounded-2xl">
            <div className="absolute inset-0 z-0">
              <Textures type="paper-1" />
            </div>
            {/* <CardHeader className="bg-opacity-50 relative z-10">
              <CardTitle className="text-3xl text-center font-semibold mt-2 text-primary-foreground w-full">
                Register
              </CardTitle>
            </CardHeader> */}
            <CardContent className="relative z-10 mt-4">
              <Form {...form}>
                <form
                  onSubmit={form.handleSubmit(onSubmit)}
                  className="pt-4 font-rosarivo max-w-2xl mx-auto"
                >
                  <div>
                    <h3 className="text-2xl text-center font-semibold mb-4 text-primary-foreground w-full">
                      Choose Your Guide
                    </h3>
                    <div className="flex flex-col gap-4 items-center w-full mb-8">
                      {guides.map((guide) => (
                        <div
                          className={cn(
                            "flex gap-3 p-1 border rounded-lg w-full transition-all duration-400 ease-in-out cursor-pointer relative",
                            selectedGuide === guide.id
                              ? "border-rose-600 bg-rose-50/30 shadow"
                              : "border-primary-foreground/20 bg-rose-50/0 hover:border-rose-600/30"
                          )}
                          key={guide.id}
                          onClick={() => handleGuideSelect(guide.id)}
                        >
                          <motion.div
                            className={`w-24 shrink-0 aspect-[9/16] z-50 inline-block bg-primary-dark border-4 border-primary-dark rounded-lg shadow-md overflow-hidden bg-cover bg-center bg-no-repeat origin-center`}
                            style={{
                              backgroundImage: `url(${guide.image})`
                            }}
                          />
                          <div className="flex flex-col justify-center">
                            <h3 className="text-2xl font-semibold text-primary-foreground">
                              {guide.name}
                            </h3>
                            {/* {selectedGuide === guide.id && (
                              <span className="text-rose-600 pointer-events-none absolute right-6 font-sans uppercase text-sm">
                                Selected
                              </span>
                            )} */}
                            <p className="italic text-primary-foreground">
                              {guide.description}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <hr className="border-primary-foreground/20 my-10" />
                  <div className="space-y-6">
                    <TarotFormField
                      name="name"
                      label="First Name"
                      control={form.control}
                      placeholder="First Name"
                    />
                    {form.formState.errors.name && (
                      <p className="text-red-500 text-sm mt-1">
                        {form.formState.errors.name.message}
                      </p>
                    )}
                    <TarotFormField
                      name="email"
                      label="Email"
                      control={form.control}
                      placeholder="your@email.com"
                      type="email"
                    />
                    {form.formState.errors.email && (
                      <p className="text-red-500 text-sm mt-1">
                        {form.formState.errors.email.message}
                      </p>
                    )}

                    <TarotFormField
                      name="password"
                      label="Password"
                      control={form.control}
                      placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                      type="password"
                    />
                    {form.formState.errors.password && (
                      <p className="text-red-500 text-sm mt-1">
                        {form.formState.errors.password.message}
                      </p>
                    )}
                  </div>

                  <hr className="border-primary-foreground/20 my-10" />
                  <div className="space-y-6">
                    <h3 className="text-2xl text-center font-semibold mb-10 text-primary-foreground w-full">
                      Choose Your Package
                    </h3>

                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-8 sm:mt-20 lg:mx-0 lg:max-w-none relative items-start">
                      {/* Premium Package */}
                      <div
                        className={cn(
                          "flex flex-col rounded-xl p-8 relative border cursor-pointer bg-primary z-10",
                          form.watch("packageType") === "premium"
                            ? "border-rose-600 "
                            : "border-primary-foreground/20 hover:border-rose-600/30"
                        )}
                        onClick={() => form.setValue("packageType", "premium")}
                      >
                        <Textures type="paper-1" />
                        <div className="relative z-10">
                          <div className="flex flex-col items-center">
                            <div className="flex items-center justify-center -mt-20 mb-10">
                              <motion.div
                                animate={{
                                  rotate: [0, 2, 0, -2, 0],
                                  scale: [1, 1.05, 1],
                                  y: [0, 10, 0]
                                }}
                                transition={{
                                  rotate: {
                                    repeat: Infinity,
                                    repeatType: "loop",
                                    duration: 15,
                                    ease: "easeInOut"
                                  },
                                  scale: {
                                    repeat: Infinity,
                                    repeatType: "loop",
                                    duration: 15,
                                    ease: "easeInOut"
                                  },
                                  y: {
                                    repeat: Infinity,
                                    repeatType: "loop",
                                    duration: 15,
                                    ease: "easeInOut",
                                    delay: 5
                                  }
                                }}
                                className={cn(
                                  "aspect-square bg-contain bg-center bg-no-repeat origin-center",
                                  "w-48 h-48"
                                )}
                                style={{
                                  backgroundImage: `url('/gemstones/amethyst-2.png')`
                                }}
                              />
                            </div>
                            <h3
                              className={cn(
                                "text-primary-foreground relative z-10",
                                "font-semibold mb-2 text-center",
                                "text-3xl"
                              )}
                            >
                              Crystal Clear <br /> Tarot Guidance
                            </h3>

                            <p className="mt-4 text-primary-foreground text-center max-w-[400px] mx-auto">
                              Establish a strong daily practise with a daily
                              reading, learn naturally through conversation with
                              your own guide, and develop your own
                              understanding, at your own pace.
                            </p>

                            <div className="flex items-center justify-center">
                              <ul className="mt-8 space-y-3 text-primary-foreground">
                                {[
                                  "Daily Reading & Interpretation",
                                  "222 Credits to Explore Your Readings",
                                  "Personal AI Tarot Guide 24/7",
                                  "Deep Dive into Card Meanings",
                                  "Access Anywhere, Anytime"
                                ].map((feature) => (
                                  <li key={feature} className="flex gap-3">
                                    <Check className="h-6 w-5 flex-none text-rose-700" />
                                    <span>{feature}</span>
                                  </li>
                                ))}
                              </ul>
                            </div>

                            <div className="flex flex-col items-center justify-center gap-4 mt-5 mb-4 relative">
                              {/* Rotating Background Blob */}
                              <div className="absolute inset-0 flex items-center justify-center blur">
                                <motion.div
                                  className={cn(
                                    "-z-10 absolute",
                                    "w-52 h-52",
                                    "bg-yellow-600/50"
                                  )}
                                  initial={{ opacity: 0 }}
                                  animate={{
                                    opacity: [0.1, 0.2, 0.1],
                                    scale: [0.7, 0.8, 0.7],
                                    borderRadius: [
                                      "30% 70% 70% 30% / 30% 30% 70% 70%",
                                      "60% 40% 30% 70% / 60% 30% 70% 40%",
                                      "30% 60% 70% 40% / 50% 60% 30% 60%",
                                      "30% 70% 70% 30% / 30% 30% 70% 70%"
                                    ]
                                  }}
                                  transition={{
                                    duration: 16,
                                    repeat: Infinity,
                                    repeatType: "mirror",
                                    ease: "easeInOut",
                                    delay: 1.6,
                                    opacity: {
                                      repeat: 1,
                                      duration: 8
                                    }
                                  }}
                                />
                              </div>
                              <div className="flex flex-col items-center justify-center text-primary-foreground text-4xl font-semibold relative drop-shadow-xl text-center space-y-4 mt-4">
                                <span>
                                  <span className="text-5xl relative">
                                    <span className="relative z-10 text-rose-600">
                                      222
                                    </span>
                                    <span className="absolute inset-0 text-rose-600/75 top-2 blur-lg">
                                      222
                                    </span>
                                  </span>
                                  <span className="text-4xl"> Credits</span>
                                </span>
                                <span className="text-3xl font-semibold tracking-tight text-primary-foreground block">
                                  For $19.99
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Free Package */}
                      <div
                        className={cn(
                          "flex flex-col rounded-xl p-6 lg:pl-10 relative border cursor-pointer bg-primary",
                          "lg:-ml-4 lg:mt-8 lg:w-fit lg:self-center",
                          form.watch("packageType") === "free"
                            ? "border-rose-600 z-20"
                            : "border-primary-foreground/20 hover:border-rose-600/30"
                        )}
                        onClick={() => form.setValue("packageType", "free")}
                      >
                        <Textures type="paper-1" />
                        <div className="relative z-10">
                          <div className="flex flex-col items-center">
                            <motion.div
                              className="w-24 h-24 mt-6 mb-6 bg-contain bg-center bg-no-repeat"
                              animate={{
                                rotate: [0, 2, 0, -2, 0],
                                scale: [1, 1.05, 1],
                                y: [0, 10, 0]
                              }}
                              transition={{
                                rotate: {
                                  repeat: Infinity,
                                  repeatType: "loop",
                                  duration: 15,
                                  ease: "easeInOut"
                                },
                                scale: {
                                  repeat: Infinity,
                                  repeatType: "loop",
                                  duration: 15,
                                  ease: "easeInOut"
                                },
                                y: {
                                  repeat: Infinity,
                                  repeatType: "loop",
                                  duration: 15,
                                  ease: "easeInOut",
                                  delay: 5
                                }
                              }}
                              style={{
                                backgroundImage: `url('/gemstones/moonstone.png')`
                              }}
                            />
                            <div className="text-center">
                              <h3 className="text-3xl font-semibold text-primary-foreground">
                                Tarot Essentials
                              </h3>
                              <p className="text-xl text-primary-foreground/80 mt-2">
                                Free
                              </p>
                            </div>
                          </div>

                          <ul className="my-8 space-y-2 max-w-[300px] mx-auto">
                            {[
                              "Daily Reading & Interpretation",
                              "5 Free Credits",
                              "Access to Basic Card Meanings"
                            ].map((feature) => (
                              <li
                                key={feature}
                                className="flex gap-x-3 text-primary-foreground"
                              >
                                <Check className="h-5 w-5 flex-none text-rose-700" />
                                {feature}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-10 mb-8 flex flex-row justify-between items-center">
                    <PushableButton
                      text={
                        mutation.isPending
                          ? "Processing..."
                          : "Begin Your Journey"
                      }
                      className="w-full"
                      isLoading={mutation.isPending}
                      type="submit"
                      disabled={mutation.isPending}
                    />
                  </div>
                </form>
              </Form>
            </CardContent>
          </Card>
        </div>
      </div>

      {/* Payment Modal */}
      <Dialog
        open={showPaymentModal}
        onOpenChange={(open) => {
          // Only allow closing if we're not processing a payment
          if (!open && !mutation.isPending) {
            setShowPaymentModal(false);
          }
        }}
      >
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            <DialogTitle className="text-2xl font-semibold text-center text-primary-foreground">
              Complete Your Journey
            </DialogTitle>
            <DialogDescription className="text-center text-primary-foreground">
              Unlock the full potential with 222 message credits and premium
              features.
            </DialogDescription>
          </DialogHeader>
          <PaymentFormWrapper
            onPaymentSuccess={handlePaymentSuccess}
            onClose={() => !mutation.isPending && setShowPaymentModal(false)}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
