import { useMutation } from "@tanstack/react-query";
import { loginUser, queryClient } from "@/utils/api";
import { useUserStore } from "@/stores/useUserStore";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from "@/components/ui/card";
import { User } from "lucide-react";
import { useNavigate } from "react-router-dom";
import Navbar from "@/components/navbar";
import { useState } from "react";
import { Input } from "@/components/ui/input";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "@/components/ui/form";
import { PushableButton } from "@/components/PushableButton";

// Define the schema for the form
const formSchema = z.object({
  email: z.string().email("Invalid email address"),
  password: z.string().min(6, "Password must be at least 6 characters")
});

type FormData = z.infer<typeof formSchema>;

export default function LoginPage() {
  const navigate = useNavigate();
  const setUser = useUserStore((state) => state.setUser);
  const [message, setMessage] = useState("");

  const form = useForm<FormData>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: ""
    }
  });

  const loginMutation = useMutation({
    mutationFn: loginUser,
    onSuccess: (data) => {
      // Save token and user data
      localStorage.setItem("token", data.token);
      setUser(data.user);
      queryClient.invalidateQueries({ queryKey: ["currentUser"] });
      navigate("/daily");
    },
    onError: (error: any) => {
      console.error("Login error:", error);
      setMessage(
        error?.response?.data?.error || "An error occurred during login"
      );
    }
  });

  const onSubmit = async (values: FormData) => {
    console.log("login");
    console.log(process.env.NODE_ENV);
    try {
      await loginMutation.mutateAsync(values);
    } catch (error) {
      console.error("Error:", error);
      setMessage("An error occurred. Please try again.");
    }
  };

  return (
    <>
      <Navbar />
      <div className="flex min-h-full flex-1 justify-center relative">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24 z-10 relative">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <Card className="w-full overflow-auto z-10 relative">
              <CardHeader className="bg-background-dark bg-opacity-50">
                <CardTitle className="font-bold tracking-tight text-gray-900 sm:text-2xl sm:leading-tight flex items-center">
                  <User className="h-6 w-6 text-rose-600 mr-2 relative -top-1" />
                  <span>Login</span>
                </CardTitle>
                <CardDescription className="mt-6 leading-8 text-gray-800 font-rosarivo">
                  Enter your credentials to access your account.
                </CardDescription>
              </CardHeader>
              <CardContent className="bg-background pt-10">
                <Form {...form}>
                  <form onSubmit={form.handleSubmit(onSubmit)}>
                    <div className="space-y-6">
                      <FormField
                        control={form.control}
                        name="email"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel htmlFor="email">Email</FormLabel>
                            <FormControl>
                              <Input
                                id="email"
                                type="email"
                                placeholder="your@email.com"
                                {...field}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="password"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel htmlFor="password">Password</FormLabel>
                            <FormControl>
                              <Input
                                id="password"
                                type="password"
                                placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                                {...field}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                    <div className="mt-10 mb-8 flex justify-between items-center">
                      <PushableButton
                        text={
                          loginMutation.isPending ? "Logging in..." : "Login"
                        }
                        isLoading={loginMutation.isPending}
                        type="submit"
                        disabled={loginMutation.isPending}
                      />
                    </div>
                  </form>
                </Form>
                {message && (
                  <p className="mt-4 text-sm text-center text-red-500">
                    {message}
                  </p>
                )}
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}
