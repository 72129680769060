import { AnimatePresence, motion, useInView } from "framer-motion";
import { useRef } from "react";
import { Textures } from "./Textures";
import BlurAnimatedGlow from "./BlurAnimatedGlow";

interface FeatureCardProps {
  title: string;
  description: string;
  position?: "tl" | "t" | "tr" | "bl" | "b" | "br";
  comingSoon?: boolean;
}

const FeatureCard = ({
  title,
  description,
  position,
  comingSoon
}: FeatureCardProps) => {
  const getRoundedCorners = () => {
    switch (position) {
      case "tl":
        return "lg:rounded-tl-[2rem]";
      case "tr":
        return "lg:rounded-tr-[2rem]";
      case "bl":
        return "lg:rounded-bl-[2rem]";
      case "br":
        return "lg:rounded-br-[2rem]";
      default:
        return "";
    }
  };

  return (
    <div className="relative">
      <div
        className={`absolute inset-px rounded-lg bg-primary ${getRoundedCorners()}`}
      >
        <Textures type="paper-1" />
      </div>
      <div
        className={`relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] ${getRoundedCorners()}`}
      >
        <div className="px-8 py-8 sm:px-10">
          {comingSoon && (
            <span className="rounded-md lg:rounded-full bg-primary-dark px-2.5 lg:py-1 text-xs  lg:text-xs/5 font-semibold text-rose-700/75 border border-rose-500/50 absolute lg:top-4 lg:right-4 top-2 right-2">
              <span className="top-[1px] font-semibold relative">
                Coming Soon
              </span>
            </span>
          )}
          <p className="mt-2 text-2xl font-medium tracking-tight text-primary-foreground max-lg:text-center">
            {title}
          </p>
          <p className="mt-2 max-w-lg text-base text-primary-foreground-muted max-lg:text-center">
            {description}
          </p>
        </div>
      </div>
      <div
        className={`pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 ${getRoundedCorners()}`}
      ></div>
    </div>
  );
};

const BentoGrid = () => {
  const features: FeatureCardProps[] = [
    {
      title: "Daily Reading Journey",
      description:
        "Deepen your tarot practice through daily draws, where each card is an opportunity for insight and reflection.",
      position: "tl"
    },
    {
      title: "Personal AI Tarot Guide",
      description:
        "Choose your personal tarot guide. Explore card meanings, ask questions, and discover personal insights anytime. Your guide is here 24/7.",
      position: "t"
    },
    {
      title: "Meaningful Conversations",
      description:
        "Turn each reading into a real conversation. Talk about psychology, symbols, myths – or just what's on your mind. Every question is welcome, big or small.",
      position: "tr"
    },
    {
      title: "Learn Tarot Naturally",
      description:
        "Skip the memorization. Learn tarot through practice and conversation, discovering each card's meaning at your own pace through real experience.",
      position: "bl"
    },
    {
      title: "Use Your Personal Deck",
      description:
        "Honor your connection with your physical deck, or draw cards digitally. Your practice, your way.",
      position: "b"
    },
    {
      title: "Advanced Practices",
      description:
        "Journey deeper with traditional spreads and yearly growth cards - new ways to explore are coming soon.",
      position: "br",
      comingSoon: true
    }
  ];

  return (
    <div className="mx-auto max-w-7xl">
      <div className="lg:mt-10 mt-5 grid gap-4 sm:mt-16 lg:grid-cols-3 lg:grid-rows-2 px-4 relative">
        <BlurAnimatedGlow />
        {features.map((feature, index) => (
          <FeatureCard key={index} {...feature} />
        ))}
      </div>
    </div>
  );
};

const FeaturesSection = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, margin: "-100px" });

  return (
    <div ref={ref} id="landing-features" className="relative">
      <motion.div className="mx-auto max-w-3xl text-primary-foreground text-center lg:pt-24 lg:mt-16 mt-20 relative">
        <div className="w-full flex justify-center mt-12">
          <motion.img
            src="/card-assets/star-0.png"
            alt="Star"
            initial={{ opacity: 0, y: 0 }}
            animate={
              isInView
                ? {
                    opacity: 1,
                    y: [0, 10, 0]
                  }
                : { opacity: 0, y: -10 }
            }
            transition={{
              opacity: { duration: 1, delay: 1 },
              y: {
                repeat: Infinity,
                repeatType: "loop",
                duration: 15,
                ease: "easeInOut"
              }
            }}
            className="lg:w-16 lg:h-16 w-10 h-10 object-contain -top-4 lg:-top-6 relative origin-center"
          />
        </div>
        <h2 className="lg:text-3xl text-2xl font-bold tracking-tight lg:leading-loose text-primary-foreground sm:leading-[3.5rem]">
          Everything You Need <br />
          For Your Tarot Journey
        </h2>
      </motion.div>
      <BentoGrid />
    </div>
  );
};

export default FeaturesSection;
