import { useLocation } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbList,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbPage,
  BreadcrumbSeparator
} from "@/components/ui/breadcrumb";
import { Fragment } from "react";
import { useBreadcrumbStore } from "@/stores/breadcrumb";

export function DynamicBreadcrumb() {
  const location = useLocation();
  const { dynamicSegments } = useBreadcrumbStore();

  const pathSegments = location.pathname
    .split("/")
    .filter((segment) => segment);

  const titleMap: Record<string, string> = {
    deck: "Your Deck",
    "daily-draw": "Daily Card",
    settings: "Settings",
    learn: "Learning Center",
    cards: "Your Deck",
    readings: "Your Readings"
  };

  const formatSegment = (segment: string, fullPath: string) => {
    if (dynamicSegments[fullPath]) {
      return dynamicSegments[fullPath];
    }

    if (
      !isNaN(Number(segment)) &&
      (fullPath.includes("/deck/") || fullPath.includes("/readings/"))
    ) {
      return "...";
    }

    return (
      titleMap[segment] ||
      segment
        .split("-")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")
    );
  };

  return (
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbItem className="hidden md:block">
          <BreadcrumbLink href="/daily">Home</BreadcrumbLink>
        </BreadcrumbItem>

        {pathSegments.map((segment, index) => {
          const isLast = index === pathSegments.length - 1;
          const path = `/${pathSegments.slice(0, index + 1).join("/")}`;

          return (
            <Fragment key={path}>
              <BreadcrumbSeparator className="hidden md:block" />
              <BreadcrumbItem className="hidden md:block">
                {isLast ? (
                  <BreadcrumbPage>
                    {formatSegment(segment, path)}
                  </BreadcrumbPage>
                ) : (
                  <BreadcrumbLink href={path}>
                    {formatSegment(segment, path)}
                  </BreadcrumbLink>
                )}
              </BreadcrumbItem>
            </Fragment>
          );
        })}
      </BreadcrumbList>
    </Breadcrumb>
  );
}
