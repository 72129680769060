import { AnimatePresence, motion } from "framer-motion";

const BlurAnimatedGlow = () => {
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, scale: 1 }}
        animate={{
          opacity: [0.1, 0.4, 0.15, 0.3, 0.1],
          scale: [1.2, 1.25, 1.2]
        }}
        exit={{
          opacity: 0,
          scale: 1,
          transition: { duration: 0.3 }
        }}
        transition={{
          opacity: {
            duration: 10,
            repeat: Infinity,
            ease: "easeInOut",
            repeatType: "loop"
          },
          scale: {
            duration: 3,
            repeat: Infinity,
            ease: "easeInOut",
            repeatType: "mirror"
          }
        }}
        className="absolute blur-[25px] rounded-full inset-0 mx-[10%] my-[5%] -z-10 bg-gradient-to-t from-orange-500/50 via-yellow-500/50 to-transparent"
      />
    </AnimatePresence>
  );
};

export default BlurAnimatedGlow;
