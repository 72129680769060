import Hero from "@/components/hero";
// import Features from "@/components/features";
// import Review from "@/components/review";
// import Pricing from "@/components/pricing";
import Footer from "@/components/footer";
// import FAQ from "@/components/faq";
// import DailyDraw from "@/components/dailyDraw";
// import LandingCardDraw from "@/components/LandingCardDraw";
import {
  motion,
  AnimatePresence,
  useScroll,
  useMotionValueEvent,
  useInView
} from "framer-motion";
import Navbar from "@/components/navbar";
import DailyDrawHero from "@/components/DailyDrawHero";
import { ReadingDemo } from "@/components/ReadingDemo";
import { useEffect, useRef, useState } from "react";
import { Textures } from "@/components/Textures";
import { guides } from "./Guides";
import PricingSection from "@/components/PricingSection";
import FAQSection from "@/components/FAQSection";
import FeaturesSection from "@/components/FeaturesSection";

interface Star {
  id: number;
  x: number;
  y: number;
  size: number;
  delay: number;
  layer: 1 | 2;
  rotationOffset: number;
}

interface Guide {
  id: number;
  name: string;
  image: string;
  description: string;
  // bulletPoints: string[];
  slogan: string;
}

const generateStars = (count: number): Star[] => {
  const stars: Star[] = [];
  const screenWidth = window.innerWidth;
  const pageHeight =
    document.documentElement.scrollHeight || document.body.scrollHeight;
  const safeZoneStart = (screenWidth - 1280) / 2;
  const safeZoneEnd = screenWidth - safeZoneStart;

  for (let i = 0; i < count; i++) {
    let x: number;
    if (Math.random() > 0.5) {
      x = Math.random() * safeZoneStart;
    } else {
      x = safeZoneEnd + Math.random() * safeZoneStart;
    }

    stars.push({
      id: i,
      x,
      y: Math.random() * pageHeight,
      size: Math.random() * 25 + 15,
      delay: Math.random(),
      layer: Math.random() > 0.5 ? 1 : 2,
      rotationOffset: Math.random() * 360
    });
  }
  return stars;
};

const AnimatedStars = () => {
  const [stars, setStars] = useState<Star[]>([]);
  const { scrollY } = useScroll();
  const [scrollPosition, setScrollPosition] = useState(0);

  useMotionValueEvent(scrollY, "change", (latest) => {
    setScrollPosition(latest);
  });

  useEffect(() => {
    // Generate initial stars
    setStars(generateStars(15));

    const handleResize = () => {
      setStars(generateStars(15));
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className="absolute inset-0 z-10 pointer-events-none overflow-hidden"
      style={{ zIndex: 1, minHeight: "100vh" }}
    >
      {stars.map((star) => (
        <motion.img
          key={star.id}
          initial={{ opacity: 0, rotate: star.rotationOffset }}
          animate={{
            opacity: [0, 0.6, 0.8, 0],
            y: [star.y, star.y + 15, star.y - 15, star.y],
            x: [star.x, star.x + 8, star.x - 8, star.x],
            rotate: [star.rotationOffset, star.rotationOffset + 360]
          }}
          transition={{
            duration: 20 + star.delay,
            repeat: Infinity,
            ease: "easeInOut",
            times: [0, 0.33, 0.66, 1],
            rotate: {
              duration: 30 + star.delay * 2,
              repeat: Infinity,
              ease: "linear",
              delay: star.delay
            }
          }}
          style={{
            width: star.size,
            position: "absolute",
            left: 0,
            top: 0,
            transform: `translate(${star.x}px, ${star.y}px)`
          }}
          src="/card-assets/star-0.png"
          alt=""
          className="z-50"
        />
      ))}
    </div>
  );
};

export default function Landing() {
  return (
    <div className="font-rosarivo relative">
      <Textures type="paper-1" />
      <AnimatedStars />
      <div className="relative z-10">
        <Navbar />
        <Hero />
        <DailyDrawHero />
        <ReadingDemo />
        <GuideSection />
        <FeaturesSection />
        <PricingSection />
        <FAQSection />
        <Footer />
      </div>
    </div>
  );
}

// const Shadows = () => {
//   const videoRef = useRef<HTMLVideoElement>(null);
//   const [isLoaded, setIsLoaded] = useState(false);

//   useEffect(() => {
//     if (videoRef.current) {
//       videoRef.current.playbackRate = 0.75;
//     }
//   }, []);

//   return (
//     <div className="fixed inset-0 w-full h-full pointer-events-none z-0">
//       <motion.div
//         initial={{ opacity: 0 }}
//         animate={{ opacity: isLoaded ? 1 : 0 }}
//         transition={{ duration: 1.5 }}
//       >
//         <video
//           ref={videoRef}
//           autoPlay
//           muted
//           loop
//           playsInline
//           onLoadedData={() => setIsLoaded(true)}
//           className="absolute inset-0 w-full h-full object-cover mix-blend-color-dodge opacity-5 z-[100]"
//           style={{ filter: "contrast(1.2) brightness(1)" }}
//         >
//           <source src="/shadows.mp4" type="video/mp4" />
//         </video>
//       </motion.div>
//     </div>
//   );
// };

const GuideSection = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, margin: "-100px" });

  return (
    <div ref={ref} id="pick-your-guide" className="relative">
      {/* <Textures type="paper-1" /> */}
      <motion.div
        className="mx-auto max-w-3xl text-primary-foreground text-center lg:pt-24 lg:mt-16 mt-16 relative"
        initial={{ opacity: 0, y: 20 }}
        animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
        transition={{ duration: 0.8, delay: 0.2 }}
      >
        <div className="w-full flex justify-center mt-12">
          {/* Rotating moon in a loop */}
          <motion.img
            src="/card-assets/wheel-of-fortune.png"
            alt="Wheel of Fortune"
            initial={{ opacity: 0, y: -10 }}
            animate={
              isInView
                ? {
                    opacity: 1,
                    rotate: [0, 360, 0]
                  }
                : { opacity: 0, y: -10 }
            }
            transition={{
              opacity: { duration: 1, delay: 1 },
              rotate: {
                repeat: Infinity,
                repeatType: "loop",
                duration: 60,
                ease: "easeInOut"
              }
            }}
            className="w-16 h-16 lg:w-24 lg:h-24 object-contain -top-2 lg:-top-6 relative origin-center"
          />
        </div>
        <h2 className="lg:text-3xl text-2xl font-bold tracking-tight lg:leading-loose text-primary-foreground sm:text-4xl sm:leading-[3.5rem] px-4">
          Pick The Guide <br />
          That's Right for You
        </h2>
        <p className="mt-2 text-lg leading-8 text-primary-foreground font-rosarivo mx-auto max-w-[450px] px-4">
          Each guide brings their own wisdom, perspective, and reading style to
          your Tarot journey. Switch between guides anytime as your needs
          evolve.
        </p>
      </motion.div>
      <motion.div
        className="container px-4 grid grid-cols-1 lg:grid-cols-4 gap-10 lg:pt-32 pt-8 relative"
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        variants={{
          hidden: { opacity: 0 },
          visible: {
            opacity: 1,
            transition: {
              staggerChildren: 0.1
            }
          }
        }}
      >
        {guides.map((guide, index) => (
          <motion.div
            key={guide.id}
            variants={{
              hidden: {
                opacity: 0,
                y: 40
              },
              visible: {
                opacity: 1,
                y: 0,
                transition: {
                  duration: 0.8,
                  ease: "easeOut"
                }
              }
            }}
          >
            <GuideCard index={index} guide={guide} />
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
};

const GuideCard = ({ guide, index }: { index: number; guide: Guide }) => {
  const [randomNumber] = useState(Math.random());
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, margin: "-100px" });

  return (
    <div ref={ref} className="relative">
      <div className="flex flex-col items-center z-10 justify-center relative">
        <div className="flex items-center justify-center relative">
          {/* Rotating Background Blob */}
          <motion.div
            className="w-48 h-48 bg-primary-foreground left-0 top-8 -z-10 absolute"
            initial={{ opacity: 0 }}
            animate={{
              opacity: [0.04, 0.06, 0.04],
              scale: [0.8, 1.1, 0.8],
              borderRadius: [
                "30% 70% 70% 30% / 30% 30% 70% 70%",
                "60% 40% 30% 70% / 60% 30% 70% 40%",
                "30% 60% 70% 40% / 50% 60% 30% 60%",
                "30% 70% 70% 30% / 30% 30% 70% 70%"
              ]
            }}
            transition={{
              duration: 16,
              repeat: Infinity,
              repeatType: "mirror",
              ease: "easeInOut",
              delay: index * 3,
              opacity: {
                repeat: 1,
                duration: 8
              }
            }}
          />
          {/* Guide image */}
          <motion.div
            className={`w-36 aspect-[9/16] z-50 inline-block bg-primary-dark border-4 border-primary-dark rounded-lg shadow-md overflow-hidden bg-[length:105%_105%] bg-center`}
            initial={{ opacity: 0 }}
            style={{
              backgroundImage: `url(${guide.image})`
            }}
            animate={{
              opacity: 1,
              y: [-2, 2, -2],
              x: [-1, 1, -1],
              rotate: [
                -1 + randomNumber * -2, // Start with random negative rotation
                1 + randomNumber * 2, // Random positive rotation
                -1 + randomNumber * -2 // Start with random negative different random
              ]
            }}
            transition={{
              opacity: { duration: 1, delay: index * 0.2 },
              y: {
                repeat: Infinity,
                duration: 8,
                ease: "easeInOut",
                delay: index * 2.3
              },
              x: {
                repeat: Infinity,
                duration: 7,
                ease: "easeInOut",
                delay: index * 2.6
              },
              rotate: {
                repeat: Infinity,
                duration: 20,
                ease: "easeInOut",
                delay: index * 1.9
              }
            }}
          />
        </div>
        {/* Description */}
        <motion.div
          initial={{ opacity: 0, rotate: 0 }}
          animate={
            isInView ? { opacity: 1, rotate: -2 + randomNumber * 5 } : {}
          }
          transition={{ duration: 0.8, delay: index * 0.2 }}
          className="flex flex-col mx-4 bg-background border-t-0 border border-primary-foreground/10 p-8 shadow-xl rounded-b-xl relative overflow-hidden"
        >
          <Textures type="paper-1" />
          {/* <div className="mb-8" /> */}
          {/* <AnimatedText text={guide.slogan} /> */}
          <div className="relative">
            <h3 className="text font-bold tracking-tight leading-loose text-primary-foreground text-2xl sm:leading-[3.5rem] relative text-center">
              {guide.name}
            </h3>
            <p className="text-primary-foreground text-center">
              {guide.description}
            </p>
          </div>
        </motion.div>
        <AnimatePresence mode="wait">
          <motion.div
            initial={{ opacity: 0, scale: 1 }}
            animate={{
              opacity: [0.4, 0.6, 0.4],
              scale: [1.2, 1.25, 1.2]
            }}
            exit={{
              opacity: 0,
              scale: 1,
              transition: { duration: 0.3 }
            }}
            transition={{
              opacity: {
                duration: 3,
                repeat: Infinity,
                ease: "easeInOut",
                repeatType: "mirror",
                delay: index * 1.5
              },
              scale: {
                duration: 3,
                repeat: Infinity,
                ease: "easeInOut",
                repeatType: "mirror"
              }
            }}
            className="absolute blur-[25px] rounded-full inset-0 w-[80%] left-[10%] top-1/2 h-1/2 -z-10 bg-gradient-to-t from-orange-500/50 via-yellow-500/50 to-transparent"
          />
        </AnimatePresence>
      </div>
    </div>
  );
};
