import refund from "./../../policies/refund.md?raw";
import privacy from "./../../policies/privacy.md?raw";
import tos from "./../../policies/terms-of-service.md?raw";
import contact from "./../../policies/contact.md?raw";
import ReactMarkdown from "react-markdown";
import Footer from "@/components/footer";
import Navbar from "@/components/navbar";

const policies = {
  refund,
  privacy: privacy,
  tos: tos,
  contact: contact
} as const;

// Create a type of the differnet keys of policies

const Policy = ({ policy }: { policy: keyof typeof policies }) => {
  return (
    <>
      <Navbar />
      <div className="px-6 py-32 lg:px-8 font-rosarivo">
        <div className="mx-auto max-w-3xl prose lg:prose-xl prose-headings:text-[#564029] prose-p:text-[#564029] prose-li:text-[#564029]">
          <ReactMarkdown>{policies[policy]}</ReactMarkdown>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Policy;
