// import { UserDeckResponse } from "@/types/Deck";
import { QueryClient } from "@tanstack/react-query";
import axios from "axios";
import { LoginResponse, User } from "@/types/api";
import { useUserStore } from "@/stores/useUserStore";
import { ChatResponse } from "@/types/api";
import { Reading } from "@/types/api";
import { Card as CardType } from "@/types/Card";
import { persister } from "@/App";

interface Guide {
  id: number;
  name: string;
  description: string;
  createdAt: Date;
  updatedAt: Date;
}

export type RegisterData = {
  name: string;
  email: string;
  password: string;
  preferredGuideId: number;
  packageType: "free" | "premium";
  initialCredits: number;
};

interface RegisterResponse {
  message: string;
  token: string;
  user: User;
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        if ((error as unknown as Response)?.status === 401) {
          return false;
        }
        return failureCount < 3;
      },
      refetchOnWindowFocus: false
    }
  }
});

const baseURL =
  // import.meta.env.PROD
  // ? "https://youruniquetarot.com/api" :
  "http://127.0.0.1:8080/api";

export const api = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json"
  }
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response?.status === 401 &&
      // Don't redirect on login/register endpoints
      !error.config.url?.includes("/login") &&
      !error.config.url?.includes("/register")
    ) {
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      queryClient.clear();
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export const getCardInterpretation = async (cardId: number) => {
  const response = await api.get(`/interpretations/cards/${cardId}`);
  return response.data;
};

export const createReading = async (data: {
  cardId: number;
  isReversed: boolean;
}) => {
  const response = await api.post("/readings", data);
  if (!response.data) {
    throw new Error("Failed to create reading");
  }
  return response.data;
};

export const getReading = async (readingId: number) => {
  const response = await api.get(`/readings/${readingId}`);
  return response.data;
};

interface ReadingsResponse {
  readings: Reading[];
  pagination: {
    total: number;
    limit: number;
    offset: number;
  };
}

export const fetchAllReadings = async (): Promise<ReadingsResponse> => {
  const response = await api.get("/readings");
  if (!response.data) {
    throw new Error("Failed to fetch readings");
  }
  return response.data;
};

export const sendUserMessage = async (
  content: string,
  conversationId: number
) => {
  const response = await api.post(`/messages`, {
    content,
    conversationId
  });
  if (!response.data) {
    throw new Error("Failed to send user message");
  }
  return response.data.message;
};

export const sendChatMessage = async (
  conversationId: number,
  cardName: string,
  conversation: string[]
): Promise<ChatResponse> => {
  try {
    const response = await api.post("/chat", {
      conversationId,
      cardName,
      conversation
    });
    if (!response.data) {
      throw new Error("Failed to send chat message");
    }
    return response.data;
  } catch (error) {
    console.error("Error in sendChatMessage:", error);
    throw error;
  }
};

export const checkIfDailyReadingExists = async () => {
  const response = await api.get(`/readings/daily`);
  return response.data;
};

export const redeemLicense = async (licenseKey: string) => {
  const response = await api.post(`/license/verify`, { licenseKey });
  return response.data;
};

export const loginUser = async (credentials: {
  email: string;
  password: string;
}): Promise<LoginResponse> => {
  const response = await api.post("/users/login", credentials);
  return response.data;
};

export const logoutUser = () => {
  // Clear auth token
  localStorage.removeItem("token");

  // Clear Zustand persisted store
  localStorage.removeItem("user-storage");
  useUserStore.getState().clearUser();

  // Clear React Query cache
  queryClient.removeQueries();
  persister.removeClient();
};

export const getCurrentUser = async () => {
  const response = await api.get("/users/me");
  return response.data;
};

export const getReadingsByCard = async (
  cardMetadataId: number
): Promise<Reading[]> => {
  try {
    if (isNaN(cardMetadataId)) {
      throw new Error("Invalid card metadata ID");
    }
    const response = await api.get(`/readings/by-card/${cardMetadataId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching readings by card:", error);
    throw error;
  }
};

export const getCard = async (cardId: number): Promise<CardType> => {
  try {
    if (isNaN(cardId)) {
      throw new Error("Invalid card ID");
    }
    const response = await api.get(`/cards/${cardId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching card:", error);
    throw error;
  }
};

export const fetchTarotCards = async (): Promise<CardType[]> => {
  const response = await api.get("/cards");
  return response.data;
};

export const getAllGuides = async (): Promise<Guide[]> => {
  const response = await api.get("/guides");
  return response.data;
};

export const getPreferredGuide = async (): Promise<Guide> => {
  const response = await api.get("/guides/preferred");
  return response.data;
};

export const updatePreferredGuide = async (guideId: number): Promise<void> => {
  const response = await api.put("/users/preferred-guide", { guideId });
  return response.data;
};

export const toggleReadingFavorite = async (
  readingId: number
): Promise<void> => {
  const response = await api.put(`/readings/${readingId}/favorite`);
  return response.data;
};

export const sendFeedback = async (message: string): Promise<void> => {
  const response = await api.post("/feedback", { message });
  return response.data;
};

export const verifyEmail = async (token: string): Promise<void> => {
  const response = await api.get(`/users/verify/${token}`);
  return response.data;
};

export const registerUser = async (
  data: RegisterData
): Promise<RegisterResponse> => {
  const response = await api.post("/users/register", data);
  return response.data;
};

export const getWeeklyReadings = async () => {
  const response = await api.get("/readings/weekly");
  return response.data;
};
